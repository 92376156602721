import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DuplicityCheckResponse } from "../types";
import { GatewayBase } from "../bases";
import { DatasetDictionary } from "../types/dataset-dictionary";

@Injectable({ providedIn: "root" })
export class DatasetDictionaryGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<DatasetDictionary[]> {
        return this._httpClient.get<DatasetDictionary[]>(`/api/dataset-dictionary`);
    }

    create(dataset: DatasetDictionary): Observable<DatasetDictionary> {
        return this._httpClient.post<DatasetDictionary>(`/api/dataset-dictionary`, dataset);
    }

    update(dataset: DatasetDictionary): Observable<DatasetDictionary> {
        return this._httpClient.patch<DatasetDictionary>(
            `/api/dataset-dictionary/${dataset.id}`,
            dataset
        );
    }

    checkDuplicity(name: string, code: string): Observable<DuplicityCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/dataset-dictionary/duplicity`, {
            name,
            code
        });
    }

    checkSurveyCodeDuplicity(surveyCode: string): Observable<boolean> {
        return this._httpClient.post<boolean>(
            `/api/dataset-dictionary/survey-code-duplicity`,
            null,
            {
                params: { surveyCode }
            }
        );
    }
}
