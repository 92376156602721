import { OnInit, OnDestroy, Component, ViewChild, inject } from "@angular/core";
import { LgPivotTableComponent } from "@logex/framework/lg-pivot-table";
import { LgPrimitive } from "@logex/framework/types";
import { atNextFrame } from "@logex/framework/utilities";
import { Subject, Observable } from "rxjs";
import { AppDefinitions, DefinitionKey } from "../services/app-definitions.service";

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({ template: "" })
export abstract class BasePageComponent implements OnInit, OnDestroy {
    _definitions = inject(AppDefinitions);

    @ViewChild("pivotTable", { static: false }) _pivotTable: LgPivotTableComponent;
    _highlightedId: LgPrimitive;

    public _initialized = false;

    protected _destroyed$ = new Subject<void>();
    protected abstract _getRequiredDefinitions(): DefinitionKey[];
    protected abstract _activate(): void;

    ngOnInit(): void {
        this._prepare().subscribe({
            complete: () => {
                this._initialized = true;
                this._activate();
            }
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    protected _prepare(): Observable<void | any[]> {
        return this._definitions.load(...this._getRequiredDefinitions());
    }

    protected _highlightChanges(ids: LgPrimitive[]): void {
        this._highlightedId = ids[ids.length - 1];
        atNextFrame(() => {
            this._pivotTable.ensureVisible(null, ids, null, true);
        });
    }
}
