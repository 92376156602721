import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    DefinitionApplication,
    DefinitionBaseProductRequester,
    DefinitionBaseProductStatus,
    DefinitionBrand,
    DefinitionBusinessUnit,
    DefinitionConfiguredDataset,
    DefinitionConfiguredService,
    DefinitionDataset,
    DefinitionDatasetDictionaryStatus,
    DefinitionOrganization,
    DefinitionProduct,
    DefinitionProductAddOn,
    DefinitionService,
    DefinitionServiceTag,
    DefinitionServiceType,
    AppDefinitionEntryWithCode
} from "../types/app-definitions";
import { map } from "rxjs/operators";
import { ServerGatewayBase } from "@logex/framework/lg-application";
import ldKeyBy from "lodash-es/keyBy";

@Injectable({ providedIn: "root" })
export class DefinitionsGateway extends ServerGatewayBase {
    _baseUrl = "/api/definitions/";

    constructor() {
        super();
        this._fakeCache = false;
    }

    getApplication(): Observable<Record<string, DefinitionApplication>> {
        return this.get<DefinitionApplication[]>(`application`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getServiceType(): Observable<Record<string, DefinitionServiceType>> {
        return this.get<DefinitionServiceType[]>(`service-type`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getService(): Observable<Record<string, DefinitionService>> {
        return this.get<DefinitionService[]>(`service`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getServiceTag(): Observable<Record<string, DefinitionServiceTag>> {
        return this.get<DefinitionServiceTag[]>(`tag`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getConfiguredService(): Observable<Record<string, DefinitionConfiguredService>> {
        return this.get<DefinitionConfiguredService[]>(`configured-service`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getOwner(): Observable<Record<string, DefinitionOrganization>> {
        return this.get<DefinitionOrganization[]>(`organization`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getDataset(): Observable<Record<string, DefinitionDataset>> {
        return this.get<DefinitionDataset[]>(`dataset`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getDatasetDictionaryStatus(): Observable<Record<string, DefinitionDatasetDictionaryStatus>> {
        return this.get<AppDefinitionEntryWithCode[]>(`dataset-dictionary-status`).pipe(
            map(res =>
                res.map((x: AppDefinitionEntryWithCode) => {
                    const status: DefinitionDatasetDictionaryStatus = {
                        ...x,
                        iconClass: "lg-icon--success",
                        icon: "icon-circle-check"
                    };

                    if (status.code === "Dataset.Status.Active") {
                        status.icon = "icon-check";
                    }

                    if (status.code === "Dataset.Status.Archived") {
                        status.iconClass = "lg-icon--gray";
                        status.icon = "icon-lock";
                    }

                    return status;
                })
            ),
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getConfiguredDataset(): Observable<Record<string, DefinitionConfiguredDataset>> {
        return this.get<DefinitionConfiguredDataset[]>(`configured-dataset`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getProduct(): Observable<Record<string, DefinitionProduct>> {
        return this.get<DefinitionProduct[]>(`base-product`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getProductAddOn(): Observable<Record<string, DefinitionProductAddOn>> {
        return this.get<DefinitionProductAddOn[]>(`addon-product`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getBrand(): Observable<Record<string, DefinitionBrand>> {
        return this.get<DefinitionBrand[]>(`brand`).pipe(map(res => ldKeyBy(res, item => item.id)));
    }

    getBusinessUnit(): Observable<Record<string, DefinitionBusinessUnit>> {
        return this.get<DefinitionBusinessUnit[]>(`business-unit`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getBaseProductRequester(): Observable<Record<string, DefinitionBaseProductRequester>> {
        return this.get<DefinitionBaseProductRequester[]>(`base-product-requester`).pipe(
            map(res => ldKeyBy(res, item => item.id))
        );
    }

    getBaseProductStatus(): Observable<Record<string, DefinitionBaseProductStatus>> {
        return this.get<DefinitionBaseProductStatus[]>(`product-status`).pipe(
            map(res =>
                res.map((x: AppDefinitionEntryWithCode) => {
                    const status: DefinitionBaseProductStatus = {
                        ...x,
                        iconClass: "lg-icon--warning",
                        icon: "icon-error"
                    };

                    if (status.code === "Product.Status.Active") {
                        status.iconClass = "lg-icon--success";
                        status.icon = "icon-check";
                    }

                    if (status.code === "Product.Status.Created") {
                        status.iconClass = "lg-icon--gray";
                        status.icon = "icon-created-not-started-state";
                    }

                    return status;
                })
            ),
            map(res => ldKeyBy(res, item => item.id))
        );
    }
}
