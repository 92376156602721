import { AbstractControl, ValidationErrors } from "@angular/forms";

const URL_REGEXP =
    // eslint-disable-next-line no-useless-escape
    /^(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/;

export function urlValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value == null || value.length === 0) {
        return null;
    }
    return URL_REGEXP.test(control.value) ? null : { url: true };
}
