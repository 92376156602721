export interface AuthorizationPermission {
    organizationId: number;
    agbCode: string;
    cicCode: number;
    mrdmUri: string;
    clientId: number;
    ziekenhuisCode: number;
    name: string;
    position: string;
    product: string;
    applicationInstance: string;
    permissions: string[];
}
