import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

import { catchError, first, switchMap, tap } from "rxjs/operators";
import { LG_LOCALIZATION_SETTINGS } from "@logex/framework/lg-localization";
import { AppConfiguration } from "../types/app-configuration";
import { ConfigService } from "./config.service";
import { AppLocalizationSettings } from "./app-localization-settings.service";
import { forkJoin, Observable, of } from "rxjs";
import { Auth0AuthorizationService } from "../auth0/auth0-authorization.service";
import { PermissionService } from "./app-permission.service";
import { AuthorizationPermission } from "../types";
import { NavigationConfigService } from "./navigation-config.service";
import { NavigationConfiguration } from "../types/navigation-configuration";

@Injectable({ providedIn: "root" })
export class AppStartupService {
    private _auth = inject(Auth0AuthorizationService);
    private _configService = inject(ConfigService);
    private _httpClient = inject(HttpClient);
    private _localizationSettings = inject<AppLocalizationSettings>(LG_LOCALIZATION_SETTINGS);
    private _navigationConfigService = inject(NavigationConfigService);
    private _permissionService = inject(PermissionService);

    initialize(): () => Promise<void> {
        return async () => {
            await this._loadConfig();
        };
    }

    private async _loadConfig(): Promise<[AuthorizationPermission[], NavigationConfiguration]> {
        return this._httpClient
            .get<AppConfiguration>(environment.config)
            .pipe(
                tap((c: AppConfiguration) => {
                    this._configService._setConfigation(c);
                    this._localizationSettings.init();
                }),
                switchMap(() => {
                    return forkJoin([
                        this._loadPermissions(),
                        this._navigationConfigService.loadNavigation()
                    ]);
                })
            )
            .toPromise();
    }

    private _loadPermissions(): Observable<AuthorizationPermission[]> {
        return this._auth.isAuthenticated$.pipe(
            first(),
            switchMap(() => this._permissionService.getPermissions()),
            catchError(() => of([]))
        );
    }
}
