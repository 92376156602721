import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LgConsoleConfiguration } from "@logex/framework/core";
import {
    EmptyAppInfo,
    IAppSession,
    LgApplicationPermissionsBase,
    LG_APP_CONFIGURATION,
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_NAVIGATION,
    LG_USER_INFO,
    ScenarioService,
    VersionService
} from "@logex/framework/lg-application";
import { LgFiltersetModule } from "@logex/framework/lg-filterset";
import {
    LgFiltersetStateGateway,
    LgLayoutModule,
    LgLocalStorageFwUiStateService,
    LG_FW_UI_STATE_SERVICE
} from "@logex/framework/lg-layout";
import {
    LgLocalizationModule,
    LG_LOCALIZATION_SETTINGS,
    ReferenceTranslateCompiler,
    useMessageFormatLocales
} from "@logex/framework/lg-localization";
import { UiCoreModule } from "@logex/framework/ui-core";
import { TranslateCompiler, TranslateModule } from "@ngx-translate/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
    AppLocalizationSettings,
    AppPermisions,
    AppStartupService,
    AppUser,
    Auth0InterceptorService,
    AUTH0_CONFIG,
    AuthorizationAppConfiguration,
    AUTHORIZATION_APP_CONFIGURATION,
    ConfigService,
    navigation,
    NavigationConfigService
} from "./shared";
import { SharedModule } from "./shared/shared.module";

const dummySession: IAppSession = {
    clientId: 10003,
    client: {
        id: 123,
        code: "123",
        name: "Test"
    },
    scenarioId: 1
};

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            compiler: { provide: TranslateCompiler, useClass: ReferenceTranslateCompiler }
        }),
        LgLocalizationModule.forRoot({
            localizationUrlPrefix: "localization"
        }),
        LgFiltersetModule.forRoot(),
        LgLayoutModule,
        UiCoreModule,
        AppRoutingModule,
        SharedModule
    ],
    providers: [
        LgFiltersetStateGateway,
        {
            provide: LgConsoleConfiguration,
            useFactory: () => {
                const config = new LgConsoleConfiguration();
                return config;
            }
        },
        {
            provide: LG_LOCALIZATION_SETTINGS,
            useClass: AppLocalizationSettings
        },
        useMessageFormatLocales(["nl", "nl-NL", "en", "en-GB"]),
        {
            provide: LOCALE_ID,
            useValue: "en-GB"
        },
        {
            provide: VersionService,
            useExisting: ScenarioService
        },
        {
            provide: LG_USER_INFO,
            useValue: new AppUser()
        },
        {
            provide: LG_APP_SESSION,
            useValue: dummySession
        },
        {
            provide: LG_APP_INFO,
            useClass: EmptyAppInfo
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (navigationcConfig: NavigationConfigService) =>
                navigation(navigationcConfig),
            deps: [NavigationConfigService]
        },
        {
            provide: NavigationConfigService,
            useClass: NavigationConfigService
        },
        {
            provide: LgApplicationPermissionsBase,
            useExisting: AppPermisions
        },
        {
            provide: AUTHORIZATION_APP_CONFIGURATION,
            useValue: new AuthorizationAppConfiguration()
        },
        {
            provide: ConfigService,
            useClass: ConfigService
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: AppStartupService) => startup.initialize(),
            deps: [AppStartupService],
            multi: true
        },
        {
            provide: AUTH0_CONFIG,
            useFactory: (startup: ConfigService) => startup.ready.then(config => config.auth0),
            deps: [ConfigService]
        },
        {
            provide: LG_APP_CONFIGURATION,
            useExisting: ConfigService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0InterceptorService,
            multi: true
        },
        {
            provide: LG_FW_UI_STATE_SERVICE,
            useClass: LgLocalStorageFwUiStateService
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {}
