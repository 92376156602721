export const ServiceTypeProduct = "Service.Type.ProductServices";
export const ServiceTypeDataset = "Service.Type.DatasetServices";
export const BaseProductStatusCreated = "Product.Status.Created";

export interface IAppDefinitions {
    application: Record<string, DefinitionApplication>;
    serviceType: Record<string, DefinitionServiceType>;
    service: Record<string, DefinitionService>;
    serviceTag: Record<string, DefinitionServiceTag>;
    configuredService: Record<string, DefinitionConfiguredService>;
    organization: Record<string, DefinitionOrganization>;
    dataset: Record<string, DefinitionDataset>;
    datasetDictionaryStatus: Record<string, DefinitionDatasetDictionaryStatus>;
    configuredDataset: Record<string, DefinitionConfiguredDataset>;
    product: Record<string, DefinitionProduct>;
    productAddOn: Record<string, DefinitionProductAddOn>;
    brand: Record<string, DefinitionBrand>;
    businessUnit: Record<string, DefinitionBusinessUnit>;
    baseProductRequester: Record<string, DefinitionBaseProductRequester>;
    baseProductStatus: Record<string, DefinitionBaseProductStatus>;
}

export interface AppDefinitionEntry {
    id: number;
    name: string;
}

export interface DefinitionBusinessUnit extends AppDefinitionEntry {
    description: string | null;
}

export interface AppDefinitionEntryWithCode extends AppDefinitionEntry {
    code: string;
}

export interface DefinitionApplication extends AppDefinitionEntryWithCode {}
export interface DefinitionServiceType extends AppDefinitionEntryWithCode {}
export interface DefinitionService extends AppDefinitionEntryWithCode {
    typeCode: "Service.Type.ProductServices" | "Service.Type.DatasetServices";
}
export interface DefinitionServiceTag extends AppDefinitionEntryWithCode {}
export interface DefinitionConfiguredService extends AppDefinitionEntry {}
export interface DefinitionOrganization extends AppDefinitionEntry {
    id: number;
    name: string;
    isInternal: boolean;
}

export interface DefinitionDataset extends AppDefinitionEntryWithCode {}

export interface DefinitionConfiguredDataset extends AppDefinitionEntry {}

export interface DefinitionDatasetDictionaryStatus extends AppDefinitionEntryWithCode {
    iconClass: string;
    icon: string;
}
export interface DefinitionProduct extends AppDefinitionEntryWithCode {}
export interface DefinitionProductAddOn extends AppDefinitionEntryWithCode {}
export interface DefinitionBrand extends AppDefinitionEntryWithCode {}

export interface DefinitionBaseProductRequester extends AppDefinitionEntryWithCode {}
export interface DefinitionBaseProductStatus extends AppDefinitionEntryWithCode {
    iconClass: string;
    icon: string;
}
