import { Component, Injectable, inject } from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { getDialogFactoryBase, LgDialogRef } from "@logex/framework/ui-core";
import { AppDefinitions } from "app/shared";

@Component({
    selector: "app-application-info",
    templateUrl: "./application-info.component.html",
    styleUrls: ["./application-info.component.scss"],
    providers: [useTranslationNamespace("APP._Dialogs.ApplicationInfo")]
})
export class ApplicationInfoComponent {
    private _definitions = inject(AppDefinitions);
    private _dialogRef = inject(LgDialogRef<ApplicationInfoComponent>);
    private _lgTranslate = inject(LgTranslateService);

    _title: string;
    _dialogClass = "lg-dialog lg-dialog--2col";
    _applicationIds: number[];

    show(applicationIds: number[]): void {
        this._applicationIds = applicationIds;
        this._title = this._lgTranslate.translate(".Title");
    }
}

// ----------------------------------------------------------------------------------
//
@Injectable()
export class ApplicationInfoComponentDialog extends getDialogFactoryBase(
    ApplicationInfoComponent,
    "show"
) {}
