import { Injectable, inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivate,
    Route,
    UrlSegment,
    Router
} from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { Auth0AuthorizationService } from "../auth0/auth0-authorization.service";
import { PermissionService } from "./app-permission.service";

@Injectable({
    providedIn: "root"
})
export class AuthorizationGuard implements CanActivate {
    private _auth = inject(Auth0AuthorizationService);
    private _permissions = inject(PermissionService);
    private _router = inject(Router);

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this._checkAccess(next.routeConfig);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._checkAccess(route);
    }

    private _checkAccess(route: Route): Observable<boolean> {
        return this._auth.isAuthenticated$.pipe(
            switchMap(loggedIn => {
                if (loggedIn) return this._permissions._permissions$;
                return of(null);
            }),
            take(1),
            switchMap(permissions => {
                if (permissions) {
                    const expected: string[] = route.data.expectedPermissions;
                    return of(this._permissions.hasPermission(expected)).pipe(
                        tap(hasAccess => {
                            if (!hasAccess) {
                                this._router.navigate(["unauthorized"]);
                            }
                        })
                    );
                } else {
                    return of(false);
                }
            })
        );
    }
}
