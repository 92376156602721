import { Component, Input, inject } from "@angular/core";
import { AppDefinitions } from "app/shared";
import { ProductDataset } from "app/shared/types/product-detail";

@Component({
    selector: "app-product-datasets",
    templateUrl: "./product-datasets.component.html",
    styleUrls: ["./product-datasets.component.scss"]
})
export class ProductDatasetsComponent {
    _definitions = inject(AppDefinitions);

    @Input() datasets: ProductDataset[];
}
