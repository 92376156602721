export interface LogsRequestParams {
    pageSize: 0;
    page: 0;
    filters: LogsFilterOptions;
}

export interface LogsFilterOptions {
    initiators?: string[];
    actionGroups?: string[];
    actionCodes?: string[];
    dateFrom?: string;
    dateTo?: string;
    targets?: LogTarget[];
}

export interface LogsPagedResult {
    totalNumberOfEntries: number;
    items: Log[];
}

export interface Log {
    id: number;
    stamp: Date;
    data: string;
    target: LogTarget;
    initiator: string;
}

export interface LogTarget {
    name: string;
    code: string;
    type: string;
    id: number;
}

export interface LogsPagedResultResponse {
    totalNumberOfEntries: number;
    items: LogResponse[];
}

export interface LogResponse {
    id: number;
    stamp: string;
    data: string;
    target: LogTarget;
    initiator: string;
}
