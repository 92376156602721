import { ALLOWED_NAME_CHARACTER_REGEXP } from "./../validators/name-format-validator";
export type CodePrefix = "Application" | "Service" | "Dataset" | "Product" | "";

export function datasetDictionaryCodeGenerator(
    datasetCode: string,
    version: number | string
): string {
    return `${datasetCode}.${version}`;
}

export function productAddOnCodeGenerator(name: string, baseProductCode: string): string {
    return `${baseProductCode}${codeGenerator(name, "")}`;
}

export function codeGenerator(name: string, type: CodePrefix): string {
    let code = name || "";

    // invalid
    if (!code.match(ALLOWED_NAME_CHARACTER_REGEXP)) return `${type}.`;

    // zero length
    if (!code.length) return `${type}.`;

    // acronyms with 1 or 2 characters, these will be written in uppercase
    if (code.match(/^([a-z]|[A-Z]){1,2}$/)) {
        code = code.toUpperCase();
        return `${type}.${code}`;
    }

    const words = code.split(/-| /).filter(i => i);
    const finalWords = words.map(w => wordTransform(w));
    const finalCode = `${type}.${finalWords.join("")}`;

    return finalCode;
}

function wordTransform(word: string): string {
    const lowerCase = isShortAcronym(word) ? word : word.toLowerCase();
    const final = lowerCase.replace(/^./, lowerCase[0].toUpperCase());
    return final;
}

function isShortAcronym(word: string): boolean {
    return /^[A-Z]{1,2}$/.test(word);
}
