export enum Filters {
    Search = "search",
    Name = "name",
    Code = "code",
    Owner = "ownerId",
    Brand = "brandId",

    ServiceTypeId = "typeId",
    ServiceId = "serviceId",

    ServiceTags = "serviceTags",

    LinkedApplications = "linkedApplications",
    AllowedDatasetServices = "allowedDatasetServices",
    ConfiguredServices = "configuredServices",

    DatasetId = "datasetId",

    Date = "date",
    Initiator = "initiator",
    Target = "target",
    ActionGroup = "actionGroup",
    Action = "action",

    Version = "version",
    Status = "status",
    StatusId = "statusId",

    BusinessUnit = "logexBusinessUnitId",
    ProductRequester = "productRequester",

    DatasetsIds = "datasetsIds",
    ServicesIds = "servicesIds",
    ApplicationsIds = "applicationsIds",

    BaseProductId = "baseProductId",

    SurveyCode = "surveyCode"
}
