import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppPermisions, Auth0Guard, AuthorizationGuard } from "./shared";

const routes: Routes = [
    {
        path: "callback",
        redirectTo: "application-definitions"
    },
    {
        path: "unauthorized",
        loadChildren: () =>
            import("./unauthorized/unauthorized.module").then(m => m.UnauthorizedModule)
    },
    {
        path: "",
        loadChildren: () => import("./authorized/authorized.module").then(m => m.AuthorizedModule),
        canLoad: [Auth0Guard, AuthorizationGuard],
        canActivate: [Auth0Guard, AuthorizationGuard],
        data: {
            expectedPermissions: AppPermisions.readPermissions
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
