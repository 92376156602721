import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { GatewayBase } from "../bases";
import { ConfigService } from "./config.service";
import { ApiType, AppUser, AuthorizationPermission } from "../types";
import { filter, tap } from "rxjs/operators";
import { AppPermisions } from "../app-permissions";
import { LG_USER_INFO } from "@logex/framework/lg-application";

@Injectable({ providedIn: "root" })
export class PermissionService extends GatewayBase {
    private _configService = inject(ConfigService);
    private _httpClient = inject(HttpClient);
    private _userInfo = inject<AppUser>(LG_USER_INFO);

    public _permissions: string[] = null;
    public _permissionsSubject = new BehaviorSubject<string[]>(null);
    public _permissions$ = this._permissionsSubject.pipe(filter(val => val !== null));

    public canEditApplications: boolean;
    public canEditServices: boolean;
    public canEditConfiguredServices: boolean;
    public canEditServiceTags: boolean;
    public canEditDatasets: boolean;
    public canEditConfiguredDatasets: boolean;
    public canEditDatasetDictionaries: boolean;
    public canEditProducts: boolean;
    public canEditBusinessUnits: boolean;
    public isAdmin: boolean;

    getPermissions(): Observable<AuthorizationPermission[]> {
        return this._httpClient
            .post<
                AuthorizationPermission[]
            >(`/user/permissions/search`, { applicationInstances: [this._configService.configuration.instance] }, { headers: { type: ApiType.authorization } })
            .pipe(
                tap((permissions: AuthorizationPermission[]) => this._setPermissions(permissions))
            );
    }

    public hasPermission(expectedPermissions: string[]): boolean {
        if (expectedPermissions == null) return true;

        for (const permission of expectedPermissions) {
            if (this._permissions.indexOf(permission) !== -1) return true;
        }

        return false;
    }

    private _setPermissions(permissionResponse: AuthorizationPermission[]): void {
        const appPermissions = permissionResponse.find(
            permission =>
                permission.applicationInstance === this._configService.configuration.instance
        );

        this._userInfo.roles = {};

        if (appPermissions) {
            this._permissions = appPermissions.permissions;
            this._permissions.forEach(x => {
                this._userInfo.roles[x] = true;
            });
            this._permissionsSubject.next(appPermissions.permissions);
        } else {
            this._permissions = [];
            this._permissionsSubject.next([]);
        }

        this._setPermissionShortcuts();
    }

    private _setPermissionShortcuts(): void {
        this.canEditApplications = this._canEditApplications;
        this.canEditServices = this._canEditServices;
        this.canEditConfiguredServices = this._canEditConfiguredServices;
        this.canEditServiceTags = this._canEditServiceTags;
        this.canEditDatasets = this._canEditDatasets;
        this.canEditConfiguredDatasets = this._canEditConfiguredDatasets;
        this.canEditDatasetDictionaries = this._canEditDatasetDictionaries;
        this.canEditProducts = this._canEditProducts;
        this.canEditBusinessUnits = this._canEditBusinessUnits;
        this.isAdmin = this._isAdmin;
    }

    private get _canEditApplications(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editApplications]);
    }

    private get _canEditServices(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editServices]);
    }

    private get _canEditConfiguredServices(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editConfiguredServices]);
    }

    private get _canEditServiceTags(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editServiceTags]);
    }

    private get _canEditDatasets(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editDatasets]);
    }

    private get _canEditConfiguredDatasets(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editConfiguredDatasets]);
    }

    private get _canEditDatasetDictionaries(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editDatasetsDictionaries]);
    }

    private get _canEditProducts(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editProducts]);
    }

    private get _canEditBusinessUnits(): boolean {
        return this.hasPermission([AppPermisions.admin, AppPermisions.editBusinessUnits]);
    }

    private get _isAdmin(): boolean {
        return this.hasPermission([AppPermisions.admin]);
    }
}
