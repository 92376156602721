import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
    selector: "app-pivot-item-list",
    templateUrl: "./pivot-item-list.component.html",
    styleUrls: ["./pivot-item-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PivotItemListComponent {
    @Input() items: string[];
    @Input() displayLimit = 1;
    @Input() translationKey: "Applications" | "Tags" | "Services" | "List" = "List";
}
