import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    ConfiguredService,
    DuplicityCheckResponse,
    Service,
    ServiceUsedApplication
} from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ServiceGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<Service[]> {
        return this._httpClient.post<Service[]>(`/api/service`, {});
    }

    create(service: Service): Observable<Service> {
        return this._httpClient.post<Service>(`/api/service/create`, service);
    }

    update(service: Service): Observable<Service> {
        return this._httpClient.patch<Service>(`/api/service/update/${service.id}`, service);
    }

    checkDuplicity(name: string, code: string): Observable<DuplicityCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/service/duplicity`, {
            name,
            code
        });
    }

    getUsedApplications(serviceId: number): Observable<Record<string, ServiceUsedApplication>> {
        return this._httpClient
            .post<ConfiguredService[]>(`/api/configured-service`, { serviceId })
            .pipe(
                map((res: ConfiguredService[]) => {
                    const result = {} as Record<string, ServiceUsedApplication>;
                    res.forEach(configuredService => {
                        configuredService.applicationsIds.forEach(application => {
                            if (result[application] != null) {
                                result[application].configuredServices.push(configuredService.id);
                            } else {
                                result[application] = {
                                    applicationId: application,
                                    configuredServices: [configuredService.id]
                                };
                            }
                        });
                    });
                    return result;
                })
            );
    }
}
