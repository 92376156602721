import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    BaseProduct,
    DuplicityCheckResponse,
    ProductAddOn,
    BaseProductUsedConfigurations,
    BaseProductInProvisioning,
    ProductDetail
} from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class BaseProductGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<BaseProduct[]> {
        return this._httpClient.get<BaseProduct[]>(`/api/base-product`);
    }

    getDetail(baseProductId: number): Observable<ProductDetail> {
        return this._httpClient
            .get<ProductDetail[]>("/api/base-product/detail", {
                params: { baseProductId }
            })
            .pipe(map(res => res[0]));
    }

    create(product: BaseProduct): Observable<BaseProduct> {
        return this._httpClient.post<BaseProduct>(`/api/base-product`, product);
    }

    update(product: BaseProduct): Observable<BaseProduct> {
        return this._httpClient.patch<BaseProduct>(`/api/base-product/${product.id}`, product);
    }

    updateStatus(productId: number, statusId: number): Observable<BaseProduct> {
        return this._httpClient.patch<BaseProduct>(`/api/base-product/update-status/${productId}`, {
            statusId
        });
    }

    checkDuplicity(name: string, code: string): Observable<DuplicityCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/base-product/duplicity`, {
            name,
            code
        });
    }

    usedConfigurations(baseProductId: number): Observable<BaseProductUsedConfigurations> {
        return this._httpClient
            .get<ProductAddOn[]>(`/api/addon-product`, { params: { baseProductId } })
            .pipe(
                map(addOnList => {
                    let usedServicesConfigurations = [];
                    let usedDatasetsConfigurations = [];
                    addOnList.forEach(addOn => {
                        usedDatasetsConfigurations = [
                            ...usedDatasetsConfigurations,
                            ...addOn.configuredDatasetsIds
                        ];
                        usedServicesConfigurations = [
                            ...usedServicesConfigurations,
                            ...addOn.configuredServicesIds
                        ];
                    });
                    return {
                        usedServicesConfigurations: Array.from(new Set(usedServicesConfigurations)),
                        usedDatasetsConfigurations: Array.from(new Set(usedDatasetsConfigurations))
                    };
                })
            );
    }

    isUsedInProvisioning(baseProductId: number): Observable<BaseProductInProvisioning> {
        return this._httpClient.get<BaseProductInProvisioning>(
            `/api/base-product/can-update/${baseProductId}`
        );
    }
}
