export enum ServiceDefinitionTab {
    Services = "Services",
    ConfiguredServices = "ConfiguredServices"
}

export interface Service {
    id: number;
    typeId: number;
    code: string;
    name: string;
    description?: string;
    linkedApplicationsIds: number[];
    tagsIds: number[];
}

export interface ServiceUsedApplication {
    applicationId: number;
    configuredServices: number[];
}
