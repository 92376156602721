// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-expand__icon {
  cursor: pointer;
  max-height: 24px;
  color: rgb(var(--gray-color-25));
}
.table-expand__icon:hover {
  color: rgb(var(--gray-color-60));
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/table-expand/table-expand.component.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,gBAAA;EACA,gCAAA;AADJ;AAGI;EACI,gCAAA;AADR","sourcesContent":["@use \"definitions\" as *;\n\n.table-expand__icon {\n    cursor: pointer;\n    max-height: 24px;\n    color: lg-color(--gray-color-25);\n\n    &:hover {\n        color: lg-color(--gray-color-60);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
