import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";
import { ConfiguredDatasetGateway } from "../gateways";

export const uniqueConfiguredDatasetNameValidator = (
    gateway: ConfiguredDatasetGateway,
    originalValue: string
) => {
    return (
        control: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if (!control.value || originalValue === control.value) return of(null);

        return timer(300).pipe(
            switchMap(() => gateway.checkDuplicity(control.value)),
            map(result => {
                return result.nameTaken ? { nameTaken: result.nameTaken } : null;
            }),
            catchError(() => of(null)),
            first()
        );
    };
};
