import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    LogsFilterOptions,
    LogsPagedResult,
    LogsPagedResultResponse,
    LogsRequestParams
} from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class LogGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getLogs(params: LogsRequestParams): Observable<LogsPagedResult> {
        return this._httpClient.post<LogsPagedResultResponse>(`/api/logs/get-paged`, params).pipe(
            map(response => {
                return {
                    totalNumberOfEntries: response.totalNumberOfEntries,
                    items: response.items.map(x => ({ ...x, stamp: new Date(x.stamp) }))
                };
            })
        );
    }

    getFiltersOptions(): Observable<LogsFilterOptions> {
        return this._httpClient.get<LogsFilterOptions>(`/api/logs/filters`);
    }
}
