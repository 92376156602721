import { Injectable } from "@angular/core";
import { StringKeyOf } from "@logex/framework/types";
import { ISimpleOrderBySpecification } from "@logex/framework/lg-pivot";

export interface OrderStorage {
    applicationList: ISimpleOrderBySpecification;
    serviceList: ISimpleOrderBySpecification;
    configuredServiceList: ISimpleOrderBySpecification;
    serviceTagList: ISimpleOrderBySpecification;
    datasetList: ISimpleOrderBySpecification;
    configuredDatasetList: ISimpleOrderBySpecification;
    datasetDictionaryList: ISimpleOrderBySpecification;
    baseProductList: ISimpleOrderBySpecification;
    productAddOnList: ISimpleOrderBySpecification;
    businessUnitList: ISimpleOrderBySpecification;
}

export type OrderStorageKey = StringKeyOf<OrderStorage>;

@Injectable({ providedIn: "root" })
export class LastOrderStorageService {
    private state: OrderStorage;

    constructor() {
        this.state = {
            applicationList: "",
            serviceList: "",
            configuredServiceList: "",
            serviceTagList: "",
            datasetList: "",
            configuredDatasetList: "",
            datasetDictionaryList: "",
            baseProductList: "",
            productAddOnList: "",
            businessUnitList: ""
        };
    }

    store(key: OrderStorageKey, currentState: ISimpleOrderBySpecification): void {
        this.state[key] = currentState;
    }

    get(key: OrderStorageKey): ISimpleOrderBySpecification {
        return this.state[key];
    }
}
