import { DefinitionSection } from "@logex/framework/lg-application";

export function idsToMultiFilter(
    ids: number[],
    definition: DefinitionSection
): Record<string, string> {
    const result = {};

    if (ids && ids.length) {
        ids.forEach(id => {
            result[id] = definition.data[id].name;
        });
    }

    return result;
}

export function multiFilterToIds(multifilter: Record<string, string>): number[] {
    return multifilter && !multifilter.$empty ? Object.keys(multifilter).map(x => Number(x)) : [];
}

export function multiFilterToValues(multifilter: Record<string, string>): string[] {
    return multifilter && !multifilter.$empty ? Object.values(multifilter) : [];
}
