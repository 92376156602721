export interface ServiceTag {
    id: number;
    name: string;
    description: string;
}

export interface CanDeleteServiceTag {
    canDelete: boolean;
    usedInServicesIds: number[];
}
