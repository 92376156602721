import { Injectable } from "@angular/core";
import { StringKeyOf } from "@logex/framework/types";
import { INodeStateStore } from "@logex/framework/lg-pivot";

export interface PivotStateStorage {
    serviceList: INodeStateStore;
    configuredServiceList: INodeStateStore;
    configuredDatasetList: INodeStateStore;
    datasetDictionaryList: INodeStateStore;
    baseProductList: INodeStateStore;
    productAddOnList: INodeStateStore;
}

export type PivotStateStorageKey = StringKeyOf<PivotStateStorage>;

@Injectable({ providedIn: "root" })
export class LastPivotStateStorageService {
    private state: PivotStateStorage;

    constructor() {
        this.state = {
            serviceList: {},
            configuredServiceList: {},
            configuredDatasetList: {},
            datasetDictionaryList: {},
            baseProductList: {},
            productAddOnList: {}
        };
    }

    store(key: PivotStateStorageKey, currentState: INodeStateStore): void {
        this.state[key] = currentState;
    }

    get(key: PivotStateStorageKey): INodeStateStore {
        return this.state[key];
    }
}
