export interface ConfiguredDataset {
    id: number;
    name: string;
    description?: string;
    datasetId: number;
    configuredServicesIds: number[];
}

export interface ConfiguredDatasetUsed {
    usedByBaseProduct: boolean;
    usedByBaseProductsIds: number[];
    usedByBaseProductsNames: string[];
    usedByAddonProduct: boolean;
    usedByAddonProductsIds: number[];
    usedByAddonProductsNames: string[];
}

export interface ConfiguredDatasetInProvisioning {
    usedInDataCollectors: boolean;
    dataCollectorsIds: number[];
    usedInDataOwners: boolean;
    dataOwnersIds: number[];
}
