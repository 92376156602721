import { AbstractControl, ValidationErrors } from "@angular/forms";

export const multifilterRequired = (minSelected: number = 1) => {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value || value.$empty) {
            return { multifilterRequired: minSelected };
        }

        return Object.keys(value).length < minSelected
            ? { multifilterRequired: minSelected }
            : null;
    };
};
