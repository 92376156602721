import { Injectable } from "@angular/core";
import { StringKeyOf } from "@logex/framework/types";

export interface FilterStorage {
    applicationList: Record<string, string>;
    serviceList: Record<string, string>;
    configuredServiceList: Record<string, string>;
    serviceTagList: Record<string, string>;
    datasetList: Record<string, string>;
    configuredDatasetList: Record<string, string>;
    datasetDictionaryList: Record<string, string>;
    baseProductList: Record<string, string>;
    productAddOnList: Record<string, string>;
    businessUnitList: Record<string, string>;
}

export type FilterStorageKey = StringKeyOf<FilterStorage>;

@Injectable({ providedIn: "root" })
export class LastFilterStorageService {
    private state: FilterStorage;

    constructor() {
        this.state = {
            applicationList: {},
            serviceList: {},
            configuredServiceList: {},
            serviceTagList: {},
            datasetList: {},
            configuredDatasetList: {},
            datasetDictionaryList: {},
            baseProductList: {},
            productAddOnList: {},
            businessUnitList: {}
        };
    }

    store(key: FilterStorageKey, currentState: Record<string, string>): void {
        this.state[key] = currentState;
    }

    get(key: FilterStorageKey): Record<string, string> {
        return this.state[key];
    }
}
