import { Injectable } from "@angular/core";
import { LgApplicationPermissionsBase } from "@logex/framework/lg-application";

@Injectable({ providedIn: "root" })
export class AppPermisions extends LgApplicationPermissionsBase {
    static readonly admin = "products_admin";

    static readonly editApplications = "products_editor_applications";
    static readonly editServices = "products_editor_services";
    static readonly editConfiguredServices = "products_editor_configured_services";
    static readonly editServiceTags = "products_editor_services_tags";
    static readonly editDatasets = "products_editor_datasets";
    static readonly editConfiguredDatasets = "products_editor_configured_datasets";
    static readonly editDatasetsDictionaries = "products_editor_dataset_dictionaries";
    static readonly editProducts = "products_editor_products";
    static readonly editBusinessUnits = "products_editor_business_units";

    static readonly readAll = "products_reader_all";

    static readonly readPermissions = [AppPermisions.admin, AppPermisions.readAll];
}
