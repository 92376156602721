import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    ConfiguredDataset,
    ConfiguredDatasetInProvisioning,
    ConfiguredDatasetUsed,
    DuplicityCheckResponse,
    DuplicityNameCheckResponse
} from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ConfiguredDatasetGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<ConfiguredDataset[]> {
        return this._httpClient.post<ConfiguredDataset[]>(`/api/configured-dataset`, {});
    }

    create(dataset: ConfiguredDataset): Observable<ConfiguredDataset> {
        return this._httpClient.post<ConfiguredDataset>(`/api/configured-dataset/create`, dataset);
    }

    update(dataset: ConfiguredDataset): Observable<ConfiguredDataset> {
        return this._httpClient.patch<ConfiguredDataset>(
            `/api/configured-dataset/${dataset.id}`,
            dataset
        );
    }

    checkDuplicity(name: string): Observable<DuplicityNameCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/configured-dataset/duplicity`, {
            name
        });
    }

    isUsed(configuredDatasetId: number): Observable<ConfiguredDatasetUsed> {
        return this._httpClient.get<ConfiguredDatasetUsed>(
            `/api/configured-dataset/can-delete/${configuredDatasetId}`
        );
    }

    isUsedInProvisioning(configuredDatasetId: number): Observable<boolean> {
        return this._httpClient
            .get(`/api/configured-dataset/can-update/${configuredDatasetId}`)
            .pipe(
                map((res: ConfiguredDatasetInProvisioning) => {
                    return res.usedInDataCollectors || res.usedInDataOwners;
                })
            );
    }

    canDelete(configuredDatasetId: number): Observable<boolean> {
        return this._httpClient
            .get<ConfiguredDatasetUsed>(`/api/configured-dataset/can-delete/${configuredDatasetId}`)
            .pipe(
                map((res: ConfiguredDatasetUsed) => {
                    return !(res.usedByAddonProduct || res.usedByBaseProduct);
                })
            );
    }

    delete(configuredDatasetId: number): Observable<any> {
        return this._httpClient.delete<any>(
            `/api/configured-dataset/delete/${configuredDatasetId}`
        );
    }
}
