import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CanDeleteServiceTag, DuplicityNameCheckResponse, ServiceTag } from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ServiceTagGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<ServiceTag[]> {
        return this._httpClient.get<ServiceTag[]>(`/api/tag`);
    }

    create(tag: ServiceTag): Observable<ServiceTag> {
        return this._httpClient.post<ServiceTag>(`/api/tag`, tag);
    }

    update(tag: ServiceTag): Observable<ServiceTag> {
        return this._httpClient.patch<ServiceTag>(`/api/tag/${tag.id}`, tag);
    }

    checkDuplicity(name: string): Observable<DuplicityNameCheckResponse> {
        return this._httpClient.post<DuplicityNameCheckResponse>(`/api/tag/duplicity`, {
            name
        });
    }

    canDelete(configuredServiceId: number): Observable<boolean> {
        return this._httpClient
            .get<CanDeleteServiceTag>(`/api/tag/can-delete/${configuredServiceId}`)
            .pipe(map((res: CanDeleteServiceTag) => res.canDelete));
    }

    usedIn(configuredServiceId: number): Observable<number[]> {
        return this._httpClient
            .get<CanDeleteServiceTag>(`/api/tag/can-delete/${configuredServiceId}`)
            .pipe(map((res: CanDeleteServiceTag) => res.usedInServicesIds));
    }

    delete(configuredServiceId: number): Observable<any> {
        return this._httpClient.delete(`/api/tag/delete/${configuredServiceId}`);
    }
}
