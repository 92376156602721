import { Injectable, inject } from "@angular/core";
import { ServerDefinitionsBase } from "@logex/framework/lg-application";
import { StringKeyOf } from "@logex/framework/types";
import ldSortBy from "lodash-es/sortBy";
import { DefinitionsGateway } from "../gateways/definitions.gateway";
import {
    AppDefinitionEntry,
    DefinitionApplication,
    DefinitionBaseProductRequester,
    DefinitionBaseProductStatus,
    DefinitionBrand,
    DefinitionBusinessUnit,
    DefinitionConfiguredDataset,
    DefinitionConfiguredService,
    DefinitionDataset,
    DefinitionDatasetDictionaryStatus,
    DefinitionOrganization,
    DefinitionProduct,
    DefinitionProductAddOn,
    DefinitionService,
    DefinitionServiceTag,
    DefinitionServiceType,
    IAppDefinitions
} from "../types/app-definitions";

export type DefinitionKey = StringKeyOf<IAppDefinitions>;

@Injectable({
    providedIn: "root"
})
export class AppDefinitions
    extends ServerDefinitionsBase<IAppDefinitions>
    implements IAppDefinitions
{
    private _definitionsGateway = inject(DefinitionsGateway);

    // ----------------------------------------------------------------------------------
    // Fields
    application: Record<string, DefinitionApplication> = this.generate<
        DefinitionApplication,
        Record<string, DefinitionApplication>
    >({
        generator: () => this._definitionsGateway.getApplication()
    });

    serviceType: Record<string, DefinitionServiceType> = this.generate<
        DefinitionServiceType,
        Record<string, DefinitionServiceType>
    >({
        generator: () => this._definitionsGateway.getServiceType()
    });

    service: Record<string, DefinitionService> = this.generate<
        DefinitionService,
        Record<string, DefinitionService>
    >({
        generator: () => this._definitionsGateway.getService()
    });

    serviceTag: Record<string, DefinitionServiceTag> = this.generate<
        DefinitionServiceTag,
        Record<string, DefinitionServiceTag>
    >({
        generator: () => this._definitionsGateway.getServiceTag()
    });

    configuredService: Record<string, DefinitionConfiguredService> = this.generate<
        DefinitionConfiguredService,
        Record<string, DefinitionConfiguredService>
    >({
        generator: () => this._definitionsGateway.getConfiguredService()
    });

    organization: Record<string, DefinitionOrganization> = this.generate<
        DefinitionOrganization,
        Record<string, DefinitionOrganization>
    >({
        generator: () => this._definitionsGateway.getOwner()
    });

    dataset: Record<string, DefinitionDataset> = this.generate<
        DefinitionDataset,
        Record<string, DefinitionDataset>
    >({
        generator: () => this._definitionsGateway.getDataset()
    });

    datasetDictionaryStatus: Record<string, DefinitionDatasetDictionaryStatus> = this.generate<
        DefinitionDatasetDictionaryStatus,
        Record<string, DefinitionDatasetDictionaryStatus>
    >({
        generator: () => this._definitionsGateway.getDatasetDictionaryStatus()
    });

    configuredDataset: Record<string, DefinitionConfiguredDataset> = this.generate<
        DefinitionConfiguredDataset,
        Record<string, DefinitionConfiguredDataset>
    >({
        generator: () => this._definitionsGateway.getConfiguredDataset()
    });

    product: Record<string, DefinitionProduct> = this.generate<
        DefinitionProduct,
        Record<string, DefinitionProduct>
    >({
        generator: () => this._definitionsGateway.getProduct()
    });

    productAddOn: Record<string, DefinitionProductAddOn> = this.generate<
        DefinitionProductAddOn,
        Record<string, DefinitionProductAddOn>
    >({
        generator: () => this._definitionsGateway.getProductAddOn()
    });

    brand: Record<string, DefinitionBrand> = this.generate<
        DefinitionBrand,
        Record<string, DefinitionBrand>
    >({
        generator: () => this._definitionsGateway.getBrand()
    });

    businessUnit: Record<string, DefinitionBusinessUnit> = this.generate<
        DefinitionBusinessUnit,
        Record<string, DefinitionBusinessUnit>
    >({ generator: () => this._definitionsGateway.getBusinessUnit() });

    baseProductRequester: Record<string, DefinitionBaseProductRequester> = this.generate<
        DefinitionBaseProductRequester,
        Record<string, DefinitionBaseProductRequester>
    >({ generator: () => this._definitionsGateway.getBaseProductRequester() });

    baseProductStatus: Record<string, DefinitionBaseProductStatus> = this.generate<
        DefinitionBaseProductStatus,
        Record<string, DefinitionBaseProductStatus>
    >({ generator: () => this._definitionsGateway.getBaseProductStatus() });

    constructor() {
        super();
        this.init();
    }

    getDisplayName(sectionName: DefinitionKey, id: number): string {
        const entry = this.getEntry(sectionName, id);

        return entry ? entry.name : "";
    }

    getSortedSectionData(sectionName: DefinitionKey): AppDefinitionEntry[] {
        return ldSortBy(Object.values(this.getSection(sectionName).data), item =>
            item.name.toLowerCase()
        );
    }
}
