import { IDropdownDefinition } from "@logex/framework/ui-core";

export interface DuplicityCheckResponse {
    nameTaken: boolean;
    codeTaken: boolean;
}

export interface DuplicityNameCheckResponse {
    nameTaken: boolean;
}

export interface FormServiceConfiguration {
    serviceId: number;
    configuredServiceId: number;
}
export interface FormDatasetConfiguration {
    datasetId: number;
    configuredDatasetId: number;
    configurationsDropdownDefinition: IDropdownDefinition<number>;
}
