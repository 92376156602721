import { HttpErrorResponse } from "@angular/common/http";
import { Component, Injectable, OnDestroy, ViewContainerRef, inject } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDropdownDefinition,
    LgConfirmationDialog,
    LgDialogRef
} from "@logex/framework/ui-core";
import { IDropdownDefinitionExtraRow } from "@logex/framework/ui-core/controls/lg-dropdown/lg-dropdown.types";
import { LgMultiFilterSourceValue } from "@logex/framework/ui-core/controls/lg-multi-filter/lg-multi-filter-popup.component";
import { atNextFrame } from "@logex/framework/utilities";
import {
    AppDefinitions,
    ConfiguredDataset,
    ConfiguredDatasetGateway,
    ConfiguredDatasetUsed,
    ConfiguredService,
    ConfiguredServiceGateway,
    Dataset,
    DatasetGateway,
    FormServiceConfiguration,
    DefinitionKey,
    PermissionService,
    uniqueConfiguredDatasetNameValidator
} from "app/shared";
import { ConfiguredServiceAddEditDialog } from "app/shared/components/configured-service-add-edit/configured-service-add-edit.component";
import ldForEach from "lodash-es/forEach";
import ldIsEqual from "lodash-es/isEqual";
import ldKeyBy from "lodash-es/keyBy";
import ldSortBy from "lodash-es/sortBy";
import { forkJoin, of, Subject } from "rxjs";
import { distinctUntilChanged, finalize, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-configured-dataset-add-edit",
    templateUrl: "./configured-dataset-add-edit.component.html",
    styleUrls: ["./configured-dataset-add-edit.component.scss"],
    providers: [useTranslationNamespace("APP._Dialogs.ConfiguredDatasetAddEditDialog")]
})
export class ConfiguredDatasetAddEditComponent implements OnDestroy {
    private _configuredServiceAddEditDialog = inject(ConfiguredServiceAddEditDialog);
    private _configuredServiceGateway = inject(ConfiguredServiceGateway);
    private _confirmDialog = inject(LgConfirmationDialog);
    private _datasetGateway = inject(DatasetGateway);
    private _definitions = inject(AppDefinitions);
    private _dialogRef = inject(LgDialogRef<ConfiguredDatasetAddEditComponent>);
    private _fb = inject(UntypedFormBuilder);
    private _gateway = inject(ConfiguredDatasetGateway);
    private _lgTranslate = inject(LgTranslateService);
    private _permissions = inject(PermissionService);

    _title: string;
    _dialogClass = "lg-dialog lg-dialog--4col";

    _isNew = false;
    _loading = true;
    _saving = false;
    _isUpdated = false;

    _configuredDataset: ConfiguredDataset;

    _form: UntypedFormGroup;

    _disableDatasetId: boolean;
    _datasetId: number;
    _datasets: Record<string, Dataset>;
    _datasetDropdownDefinition: IDropdownDefinition<number>;

    _selectedServices: { $empty: true };
    _serviceDropdownDefinition: () => LgMultiFilterSourceValue;

    private _configuredServices: Record<string, ConfiguredService>;
    _configurationDropdownDefinitions: Record<string, IDropdownDefinition<number>> = {};

    _isUsed: ConfiguredDatasetUsed;
    _isUsedInProvisioning: boolean;
    _showIsUsed: boolean;

    private _initFormValue: ConfiguredDataset;
    private _destroyed$ = new Subject<void>();

    constructor() {
        const _viewContainerRef = inject(ViewContainerRef);

        this._confirmDialog = this._confirmDialog.bindViewContainerRef(_viewContainerRef);
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    show(
        datasetId?: number,
        configuredDataset?: ConfiguredDataset,
        disableDatasetId?: boolean
    ): Promise<ConfiguredDataset> {
        this._isNew = !configuredDataset;
        this._title = this._lgTranslate.translate(this._isNew ? ".AddTitle" : ".EditTitle");
        this._datasetId = datasetId || configuredDataset?.datasetId || null;
        this._configuredDataset = configuredDataset;
        this._disableDatasetId = disableDatasetId;

        this._load();

        return this._createPromise();
    }

    _confirm(): void {
        if (this._form.invalid) return;
        this._saving = true;

        let request = this._gateway.create(this._updatedConfiguredDataset);
        let errorTitle = ".Errors.CreateErrorTitle";
        let errorText = ".Errors.CreateErrorText";

        if (!this._isNew) {
            request = this._gateway.update(this._updatedConfiguredDataset);
            errorTitle = ".Errors.UpdateErrorTitle";
            errorText = ".Errors.UpdateErrorText";
        }

        request.pipe(finalize(() => (this._saving = false))).subscribe(
            (configuredDataset: ConfiguredDataset) => {
                this._definitions.clearCache("configuredDataset");
                this._resolve(configuredDataset);
                this._dialogRef.close();
            },
            (errorResponse: HttpErrorResponse) => {
                this._confirmDialog.warningLc(
                    errorTitle,
                    errorResponse?.error?.ErrorData || errorResponse?.error?.title || errorText
                );
            }
        );
    }

    _close(): void {
        this._dialogRef.close();
    }

    _cancel(): void {
        this._tryClose();
    }

    _tryClose(): boolean {
        if (this._saving) return;

        if (this._loading || ldIsEqual(this._initFormValue, this._updatedConfiguredDataset)) {
            this._close();
            return;
        }

        this._confirmDialog
            .confirmLC(".ConfirmClose", ".ConfirmDiscardingUnsavedData")
            .then(response => {
                if (response === 1) {
                    this._close();
                }
            });
    }

    _serviceSelected(e: any): void {
        if (e.$empty || this._isUsedInProvisioning) return;

        Object.keys(e).forEach(serviceId => {
            this.servicesControls.push(this._serviceFormGroup(Number(serviceId)));
        });

        this._selectedServices = { $empty: true };
    }

    _deleteService(index: number): void {
        if (this._isUsedInProvisioning) return;
        this.servicesControls.removeAt(index);
    }

    private _serviceFormGroup(serviceId: number, configuredServiceId?: number): UntypedFormGroup {
        const group = this._fb.group({
            serviceId: [serviceId, Validators.required],
            serviceName: [this._definitions.getDisplayName("service", serviceId)],
            configuredServiceId: [
                { value: configuredServiceId, disabled: this._isUsedInProvisioning },
                Validators.required
            ]
        });

        return group;
    }

    get servicesControls(): UntypedFormArray {
        return this._form.get("services") as UntypedFormArray;
    }

    private _getRequiredDefinitions(): DefinitionKey[] {
        return ["dataset", "configuredService", "service", "product", "productAddOn"];
    }

    private _load(): void {
        forkJoin([
            this._datasetGateway.getAll(),
            this._configuredServiceGateway.getAll(),
            this._definitions.load(...this._getRequiredDefinitions()),
            this._isNew ? of(null) : this._gateway.isUsed(this._configuredDataset.id),
            this._isNew ? of(null) : this._gateway.isUsedInProvisioning(this._configuredDataset.id)
        ])
            .pipe(
                takeUntil(this._destroyed$),
                finalize(() => (this._loading = false))
            )
            .subscribe(
                ([datasets, configuredServices, definitions, isUsed, isUsedInProvisioning]) => {
                    this._datasets = ldKeyBy(datasets, "id");
                    this._isUsedInProvisioning = isUsedInProvisioning;
                    this._setConfigurationDefinitions(configuredServices);
                    this._initDropdowns();
                    this._initForm();
                    this._initIsUsed(isUsed);
                    atNextFrame(() => this._dialogRef.center());
                }
            );
    }

    private _initIsUsed(isUsed: ConfiguredDatasetUsed): void {
        if (this._isNew) return;

        this._isUsed = isUsed;
        this._showIsUsed = isUsed.usedByAddonProduct || isUsed.usedByBaseProduct;

        this._isUsed.usedByBaseProductsNames = this._isUsed.usedByBaseProductsIds.map(x =>
            this._definitions.getDisplayName("product", x)
        );

        this._isUsed.usedByAddonProductsNames = this._isUsed.usedByAddonProductsIds.map(x =>
            this._definitions.getDisplayName("productAddOn", x)
        );
    }

    private _setConfigurationDefinitions(configuredServices: ConfiguredService[]): void {
        this._configuredServices = ldKeyBy(configuredServices, "id");
        ldForEach(this._definitions.service, service => {
            const definition = {
                groups: [
                    {
                        entries: ldSortBy(
                            configuredServices.filter(x => x.serviceId === service.id),
                            item => item.name.toLowerCase()
                        )
                    }
                ],
                extraBottom: this._getExtraDefinitionRow(service.id)
            } as IDropdownDefinition<number>;
            this._configurationDropdownDefinitions[service.id] = definition;
        });
    }

    private _getExtraDefinitionRow(serviceId: number): IDropdownDefinitionExtraRow {
        if (!this._permissions.canEditConfiguredServices) return null;

        return {
            name: this._lgTranslate.translate(".Labels.NewServiceConfiguration"),
            icon: "icon-add",
            onClick: () => {
                this._addServiceConfiguration(serviceId);
                return true;
            },
            close: false
        };
    }

    private _addServiceConfiguration(serviceId: number): void {
        this._configuredServiceAddEditDialog.show(null, serviceId, true).then(configuration => {
            const def = this._configurationDropdownDefinitions[configuration.serviceId];
            def.groups[0].entries.push(configuration);
            def.groups[0].entries = ldSortBy(def.groups[0].entries, item =>
                item.name.toLowerCase()
            );
            def.lookup[configuration.id] = configuration;
            this._configurationDropdownDefinitions[configuration.serviceId] = { ...def };
        });
    }

    private _initForm(): void {
        this._form = this._fb.group({
            datasetId: [
                { value: this._datasetId, disabled: !this._isNew || this._disableDatasetId },
                [Validators.required]
            ],
            name: [
                { value: this._configuredDataset?.name, disabled: this._datasetId == null },
                [Validators.required],
                [uniqueConfiguredDatasetNameValidator(this._gateway, this._configuredDataset?.name)]
            ],
            description: [
                { value: this._configuredDataset?.description, disabled: this._datasetId == null }
            ],
            services: this._fb.array([])
        });

        if (!this._isNew) {
            this._configuredDataset.configuredServicesIds.forEach(configurationId => {
                const configuration = this._configuredServices[configurationId];
                this.servicesControls.push(
                    this._serviceFormGroup(configuration.serviceId, configuration.id)
                );
            });
        }

        this._form
            .get("datasetId")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
                this._datasetId = value;
                this._form.get("name").reset("");
                this._form.get("description").reset("");
                this.servicesControls.clear();
                if (value == null) {
                    this._form.get("name").disable();
                    this._form.get("description").disable();
                    this.servicesControls.disable();
                } else {
                    this._form.get("name").enable();
                    this._form.get("description").enable();
                    this.servicesControls.enable();
                }
            });

        if (!this._isNew) {
            this._configuredDataset.configuredServicesIds.forEach(configurationId => {
                const configuration = this._configuredServices[configurationId];
                this._serviceFormGroup(configuration.serviceId, configurationId);
            });
        }

        this._initFormValue = { ...this._updatedConfiguredDataset };

        if (!this._isNew) {
            this._form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
                this._isUpdated = !ldIsEqual(this._initFormValue, this._updatedConfiguredDataset);
            });
        }
    }

    private _initDropdowns(): void {
        this._datasetDropdownDefinition = {
            groups: [
                {
                    entries: this._definitions.getSortedSectionData("dataset")
                }
            ]
        };

        this._serviceDropdownDefinition = () => {
            const selected = this.servicesControls.value.map(
                (x: FormServiceConfiguration) => x.serviceId
            );

            return ldSortBy(
                this._datasets[this._datasetId].allowedServicesIds
                    .filter(x => !selected.includes(x))
                    .map(x => this._definitions.getEntry("service", x)),
                item => item.name.toLowerCase()
            );
        };
    }

    private get _updatedConfiguredDataset(): ConfiguredDataset {
        const formValue = this._form.getRawValue();
        const updatedApplication = {
            id: this._configuredDataset?.id,
            datasetId: formValue.datasetId,
            name: formValue.name,
            description: formValue.description || undefined,
            configuredServicesIds: formValue.services.map(
                (x: FormServiceConfiguration) => x.configuredServiceId
            )
        } as ConfiguredDataset;

        return updatedApplication;
    }

    private _resolve: (result: ConfiguredDataset) => void;
    private _reject: (reason?: any) => void;

    private _createPromise(): Promise<ConfiguredDataset> {
        return new Promise<ConfiguredDataset>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
}

// ----------------------------------------------------------------------------------
//
@Injectable()
export class ConfiguredDatasetAddEditDialog extends getDialogFactoryBase(
    ConfiguredDatasetAddEditComponent,
    "show"
) {}
