import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { UiCoreModule } from "@logex/framework/ui-core";
import { UmSidebarUserComponent } from "./components/um-sidebar-user/um-sidebar-user.component";
import { PivotSearchComponent } from "./components/pivot-search/pivot-search.component";
import { AbsoluteUrlPipe, EnumTranslatePipe } from "./pipes";
import { PivotItemListComponent } from "./components/pivot-item-list/pivot-item-list.component";
import { MultiFilterButtonComponent } from "./components/multi-filter-button/multi-filter-button.component";
import {
    ConfiguredServiceAddEditComponent,
    ConfiguredServiceAddEditDialog
} from "./components/configured-service-add-edit/configured-service-add-edit.component";
import { PagingComponent } from "./components/paging/paging.component";
import {
    DownloadDialog,
    DownloadDialogComponent
} from "./components/download-dialog/download-dialog.component";
import {
    ConfiguredDatasetAddEditComponent,
    ConfiguredDatasetAddEditDialog
} from "./components/configured-dataset-add-edit/configured-dataset-add-edit.component";
import { DropdownPanelIconButtonComponent } from "./components/dropdown-panel-icon-button/dropdown-panel-icon-button.component";
import {
    ApplicationInfoComponent,
    ApplicationInfoComponentDialog
} from "./components/configured-service-add-edit/application-info/application-info.component";
import {
    ProductDetailComponent,
    ProductDetailDialog
} from "./components/product-detail/product-detail.component";
import { ProductServicesComponent } from "./components/product-detail/product-services/product-services.component";
import { ProductDatasetsComponent } from "./components/product-detail/product-datasets/product-datasets.component";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { TableExpandComponent } from "./components/table-expand/table-expand.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LgLocalizationModule,
        UiCoreModule,
        UiToolboxModule,
        LgPivotTableModule
    ],
    declarations: [
        UmSidebarUserComponent,
        PivotSearchComponent,
        EnumTranslatePipe,
        AbsoluteUrlPipe,
        PivotItemListComponent,
        MultiFilterButtonComponent,
        ConfiguredServiceAddEditComponent,
        ConfiguredDatasetAddEditComponent,
        PagingComponent,
        DownloadDialogComponent,
        DropdownPanelIconButtonComponent,
        ApplicationInfoComponent,
        ProductDetailComponent,
        ProductDatasetsComponent,
        ProductServicesComponent,
        TableExpandComponent
    ],
    providers: [
        EnumTranslatePipe,
        AbsoluteUrlPipe,
        ConfiguredServiceAddEditDialog,
        ConfiguredDatasetAddEditDialog,
        ApplicationInfoComponentDialog,
        ProductDetailDialog,
        DownloadDialog
    ],
    exports: [
        UmSidebarUserComponent,
        PivotSearchComponent,
        EnumTranslatePipe,
        AbsoluteUrlPipe,
        PivotItemListComponent,
        MultiFilterButtonComponent,
        PagingComponent,
        DropdownPanelIconButtonComponent
    ]
})
export class SharedModule {}
