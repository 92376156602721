import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { PageInfo } from "app/shared";

@Component({
    selector: "app-paging",
    templateUrl: "./paging.component.html",
    styleUrls: ["./paging.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagingComponent {
    @Input() totalEntries: number;
    @Input() activePage: number;
    @Input() pageSize: number;
    @Output() readonly pageChange = new EventEmitter<number>();

    get _totalPages(): number {
        return Math.ceil(this.totalEntries / this.pageSize);
    }

    get _pageInfo(): PageInfo {
        let start = (this.activePage - 1) * this.pageSize + 1;
        let end = this.activePage * this.pageSize;

        if (start > this.totalEntries) {
            start = this.totalEntries;
        }

        if (end > this.totalEntries) {
            end = this.totalEntries;
        }

        return { start, end, total: this.totalEntries };
    }

    _pageChange(page: number): void {
        if (page >= 1 && page <= this._totalPages) {
            this.pageChange.emit(page);
        }
    }
}
