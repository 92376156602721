import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Application, DuplicityCheckResponse } from "../types";
import { GatewayBase } from "../bases";

@Injectable({ providedIn: "root" })
export class ApplicationGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getApplications(): Observable<Application[]> {
        return this._httpClient.get<Application[]>(`/api/application`);
    }

    create(application: Application): Observable<Application> {
        return this._httpClient.post<Application>(`/api/application`, application);
    }

    update(application: Application): Observable<Application> {
        return this._httpClient.patch<Application>(`/api/application/${application.id}`, {
            documentation: application.documentation,
            description: application.description
        });
    }

    applicationDuplicity(name: string, code: string): Observable<DuplicityCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/application/duplicity`, {
            name,
            code
        });
    }
}
