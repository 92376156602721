import { Component, OnInit, inject } from "@angular/core";
import { Auth0AuthorizationService } from "../../auth0/auth0-authorization.service";

@Component({
    selector: "um-sidebar-user",
    templateUrl: "./um-sidebar-user.component.html",
    styleUrls: ["./um-sidebar-user.component.scss"]
})
export class UmSidebarUserComponent implements OnInit {
    private _auth0 = inject(Auth0AuthorizationService);

    _userName: string;
    _userEmail: string;
    _userPicture: string;

    ngOnInit(): void {
        this._auth0.getUser().subscribe(user => {
            this._userName = `${user.given_name} ${user.family_name}`;
            this._userEmail = user.email;
            this._userPicture = user.picture;
        });
    }

    _logout(e: Event): void {
        e.stopPropagation();
        e.preventDefault();
        this._auth0.logout();
    }
}
