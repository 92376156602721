import { Injectable } from "@angular/core";
import { AppConfiguration } from "../types/app-configuration";

@Injectable()
export class ConfigService {
    applicationRoot: string;
    configuration: AppConfiguration;
    ready: Promise<AppConfiguration>;
    private _resolve: (value: AppConfiguration) => void;

    constructor() {
        this.ready = new Promise<AppConfiguration>(resolve => {
            this._resolve = resolve;
        });
    }

    _setConfigation(val: AppConfiguration): void {
        this.configuration = val;
        this.applicationRoot = val.api.applicationUrl;
        this._resolve(val);
        this._resolve = null;
    }

    get isLocalOrTestEnvironment(): boolean {
        if (this.configuration) {
            return (
                this.configuration.environment === "local" ||
                this.configuration.environment === "test"
            );
        }
        return false;
    }
}
