export enum DatasetDefinitionTab {
    Datasets = "Datasets",
    ConfiguredDatasets = "ConfiguredDatasets",
    DatasetsDictionaries = "DatasetsDictionaries"
}

export interface Dataset {
    id: number;
    name: string;
    code: string;
    description?: string;
    ownerId: number;
    brandId: number;
    allowedServicesIds: number[];
}

export interface DatasetUsedServicesResponse {
    configuredDatasetId: number;
    usedServices: number[];
}

export interface DatasetUsedServices {
    serviceId: number;
    configuredDatasets: number[];
}
