import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, Injectable, OnDestroy, ViewContainerRef, inject } from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { getDialogFactoryBase, LgConfirmationDialog, LgDialogRef } from "@logex/framework/ui-core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-download-dialog",
    templateUrl: "./download-dialog.component.html",
    styleUrls: ["./download-dialog.component.scss"],
    providers: [useTranslationNamespace("APP._Dialogs.DownloadDialog")]
})
export class DownloadDialogComponent implements OnDestroy {
    private _confirmDialog = inject(LgConfirmationDialog);
    private _dialogRef = inject(LgDialogRef<DownloadDialogComponent>);
    private _httpClient = inject(HttpClient);
    private _lgTranslate = inject(LgTranslateService);

    _title: string;
    _dialogClass = "lg-dialog lg-dialog--2col";
    _progress: number;

    private _destroyed$ = new Subject<void>();

    constructor() {
        const _viewContainerRef = inject(ViewContainerRef);

        this._confirmDialog = this._confirmDialog.bindViewContainerRef(_viewContainerRef);
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    show(url: string, params: any, filename: string): Promise<void> {
        this._title = this._lgTranslate.translate(".Title");
        this._download(url, params, filename);
        return this._createPromise();
    }

    private _download(url: string, params: any, filename: string): void {
        this._httpClient
            .post(url, params, {
                responseType: "blob",
                reportProgress: true,
                observe: "events"
            })
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                (response: HttpEvent<Blob>) => {
                    if (response.type === HttpEventType.DownloadProgress) {
                        this._progress = Math.round((100 * response.loaded) / response.total);
                    }
                    if (response.type === HttpEventType.Response) {
                        this._progress = 100;

                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(response.body);
                        link.download = filename;

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        this._dialogRef.close();
                    }
                },
                () => {
                    this._confirmDialog.alertLC(".ErrorTitle", ".ErrorText").then(() => {
                        this._dialogRef.close();
                    });
                }
            );
    }

    private _resolve: (result: void) => void;
    private _reject: (reason?: any) => void;

    private _createPromise(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
}

// ----------------------------------------------------------------------------------
//
@Injectable()
export class DownloadDialog extends getDialogFactoryBase(DownloadDialogComponent, "show") {}
