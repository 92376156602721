import { AbstractControl, ValidationErrors } from "@angular/forms";

// only [a-z], [A-Z], [0-9], " ", "-"
export const ALLOWED_NAME_CHARACTER_REGEXP = /^([a-z]|[A-Z]|[0-9]| |-)+$/;

// no " "/"-" at start/end, no " -"/"- " (" - " allowed) ,no following " "/"-"
const WRONG_FORMAT_REGEXP = /(^(-| ))|((-| )$)|((?<! )(- ))|(( -)(?! ))|(- -)|( {2})|(-{2})/g;

export function nameFormatValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value == null || value.length === 0) {
        return null;
    }

    WRONG_FORMAT_REGEXP.lastIndex = 0;
    let invalidCharacters = false;
    let invalidFormat = false;
    let showError = false;

    invalidCharacters = !ALLOWED_NAME_CHARACTER_REGEXP.test(value);
    invalidFormat = WRONG_FORMAT_REGEXP.test(value);
    showError = invalidCharacters || invalidFormat;

    return showError ? { invalidCharacters, invalidFormat } : null;
}
