import { Component, Input } from "@angular/core";

export interface ExpandableItem {
    $expanded: boolean;
}

@Component({
    selector: "app-table-expand",
    templateUrl: "./table-expand.component.html",
    styleUrl: "./table-expand.component.scss"
})
export class TableExpandComponent {
    @Input() item: ExpandableItem;

    readonly _iconExpanded = "icon-arrow-down";
    readonly _iconCollapsed = "icon-arrow-right";

    _toggle(event?: Event): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.item.$expanded = !this.item.$expanded;
    }
}
