import { FormDatasetConfiguration, FormServiceConfiguration } from "./base-types";

export enum ProductDefinitionTab {
    BaseProducts = "BaseProducts",
    AddOns = "AddOns"
}

export interface BaseProduct {
    id: number;
    name: string;
    code: string;
    description?: string;
    logexBusinessUnitId: number;
    statusId: number;
    productRequester: number;
    brandId: number;
    datasetsIds: number[];
    servicesIds: number[];
    applicationsIds: number[];
    configuredDatasetsIds: number[];
    configuredServicesIds: number[];
}

export interface BaseProductFormValue {
    name: string;
    code: string;
    description: string;
    logexBusinessUnitId: number;
    statusId: number;
    productRequester: number;
    brandId: number;
    datasets: FormDatasetConfiguration[];
    services: FormServiceConfiguration[];
}

export interface BaseProductUsedConfigurations {
    usedServicesConfigurations: number[];
    usedDatasetsConfigurations: number[];
}

export interface BaseProductInProvisioning {
    usedForDataCollectors: boolean;
    dataCollectorsIds: number[];
    usedForDataOwners: boolean;
    dataOwnersIds: number[];
}
