import { INavNode } from "@logex/framework/lg-application";
import { NavigationConfigService } from "./services/navigation-config.service";
import { NavigationConfigurationItem } from "./types/navigation-configuration";

export const navigation: (
    navigationConfig: NavigationConfigService
) => INavNode[] = navigationConfig => {
    const nav = [
        {
            path: "",
            id: "root",
            noBreadcrumb: true,
            children: []
        },
        {
            path: "",
            id: "settings",
            name: "Settings",
            noBreadcrumb: false,
            children: []
        }
    ] as INavNode[];

    addAppNavigation(navigationConfig.configuration.organizations, nav);
    addAppNavigation(navigationConfig.configuration.products, nav);
    addAppNavigation(navigationConfig.configuration.clientsPortfolios, nav);
    addAppNavigation(navigationConfig.configuration.userManagement, nav);
    addAppNavigation(navigationConfig.configuration.registryManagement, nav);

    return nav;
};

function addAppNavigation(item: NavigationConfigurationItem, nav: INavNode[]): void {
    if (item.root) {
        nav[0].children.push(item.root);
    }
    if (item.settings) {
        nav[1].children.push(item.settings);
    }
}
