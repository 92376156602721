import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";
import { ServiceGateway } from "../gateways";
import { codeGenerator } from "../helpers";

export const uniqueServiceNameValidator = (
    applicationService: ServiceGateway,
    originalValue: string
) => {
    return (
        control: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if (!control.value || originalValue === control.value) return of(null);

        return timer(300).pipe(
            switchMap(() =>
                applicationService.checkDuplicity(
                    control.value,
                    codeGenerator(control.value, "Service")
                )
            ),
            map(result => {
                const exists = result.codeTaken || result.nameTaken;
                return exists ? { codeTaken: result.codeTaken, nameTaken: result.nameTaken } : null;
            }),
            catchError(() => of(null)),
            first()
        );
    };
};
