import { Auth0Config } from "../auth0";
import { NavigationPaths } from "./navigation-configuration";

export class AppConfiguration {
    instance: string;
    environment: string;
    auth0: Auth0Config;
    navigationPaths: NavigationPaths;
    api: {
        applicationUrl: string;
        authorizationUrl: string;
    };
}
