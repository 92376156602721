export interface ProductAddOn {
    id: number;
    name: string;
    code: string;
    description?: string;
    baseProductId: number;
    configuredDatasetsIds: number[];
    configuredServicesIds: number[];

    datasetsIds?: number[];
    servicesIds?: number[];
    applicationsIds?: number[];
}

export interface AddOnProductInProvisioning {
    usedForDataCollectors: boolean;
    dataCollectorsIds: number[];
    usedForDataOwners: boolean;
    dataOwnersIds: number[];
}
