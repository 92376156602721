import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    Dataset,
    DatasetUsedServices,
    DatasetUsedServicesResponse,
    DuplicityCheckResponse
} from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class DatasetGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<Dataset[]> {
        return this._httpClient.post<Dataset[]>(`/api/dataset`, {});
    }

    create(dataset: Dataset): Observable<Dataset> {
        return this._httpClient.post<Dataset>(`/api/dataset/create`, dataset);
    }

    update(dataset: Dataset): Observable<Dataset> {
        return this._httpClient.patch<Dataset>(`/api/dataset/update/${dataset.id}`, dataset);
    }

    checkDuplicity(name: string, code: string): Observable<DuplicityCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/dataset/duplicity`, {
            name,
            code
        });
    }

    getUsedServices(datasetId: number): Observable<Record<string, DatasetUsedServices>> {
        return this._httpClient
            .get<DatasetUsedServicesResponse[]>(`/api/dataset/used-services/${datasetId}`)
            .pipe(
                map((res: DatasetUsedServicesResponse[]) => {
                    const result = {} as Record<string, DatasetUsedServices>;
                    res.forEach(configuredDataset => {
                        configuredDataset.usedServices.forEach(service => {
                            if (result[service] != null) {
                                result[service].configuredDatasets.push(
                                    configuredDataset.configuredDatasetId
                                );
                            } else {
                                result[service] = {
                                    serviceId: service,
                                    configuredDatasets: [configuredDataset.configuredDatasetId]
                                };
                            }
                        });
                    });
                    return result;
                })
            );
    }
}
