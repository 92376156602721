import { IFilterOption } from "@logex/framework/types";
import ldSortBy from "lodash-es/sortBy";

export function uniqueFilterIds(arr: number[][]): number[] {
    let result = [];
    arr.forEach(x => (result = [...result, ...x]));
    return Array.from(new Set(result));
}

export function idsToMultifilterSorted(
    ids: number[],
    nameCallback: (id: number) => string
): IFilterOption[] {
    const filterOptions: IFilterOption[] = ids.map(x => ({
        id: x,
        name: nameCallback(x)
    }));

    return ldSortBy(filterOptions, item => item.name.toLowerCase());
}
