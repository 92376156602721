import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";
import { ProductAddOnGateway } from "../gateways";
import { productAddOnCodeGenerator } from "../helpers";

export const uniqueProductAddOnNameValidator = (
    gateway: ProductAddOnGateway,
    originalName: string,
    originalCode: string,
    isNew: boolean,
    productCode: () => string,
    productId: () => number
) => {
    return (
        control: AbstractControl
    ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if (!control.value || originalName === control.value) return of(null);

        const name = control.value;
        const code = isNew ? productAddOnCodeGenerator(control.value, productCode()) : originalCode;

        return timer(300).pipe(
            switchMap(() => gateway.checkDuplicity(name, code, productId())),
            map(result => {
                const codeTaken = isNew ? result.codeTaken : false;
                const nameTaken = result.nameTaken;

                return nameTaken || codeTaken ? { codeTaken, nameTaken } : null;
            }),
            catchError(() => of(null)),
            first()
        );
    };
};
