import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "absoluteUrl",
    pure: true
})
export class AbsoluteUrlPipe implements PipeTransform {
    transform(value: string): any {
        if (value == null) {
            return "";
        }
        value = value.toLocaleLowerCase();
        if (value.startsWith("http://") || value.startsWith("https://")) {
            return value;
        }
        return `http://${value}`;
    }
}
