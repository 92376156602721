import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    ProductAddOn,
    DuplicityCheckResponse,
    AddOnProductInProvisioning,
    ProductDetail
} from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ProductAddOnGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<ProductAddOn[]> {
        return this._httpClient.get<ProductAddOn[]>(`/api/addon-product`);
    }

    getDetail(addonProductId: number): Observable<ProductDetail> {
        return this._httpClient
            .get<ProductDetail[]>("/api/addon-product/detail", {
                params: { addonProductId }
            })
            .pipe(map(res => res[0]));
    }

    create(addon: ProductAddOn): Observable<ProductAddOn> {
        return this._httpClient.post<ProductAddOn>(`/api/addon-product`, addon);
    }

    update(addon: ProductAddOn): Observable<ProductAddOn> {
        return this._httpClient.patch<ProductAddOn>(`/api/addon-product/${addon.id}`, addon);
    }

    checkDuplicity(
        name: string,
        code: string,
        baseProductId: number
    ): Observable<DuplicityCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/addon-product/duplicity`, {
            name,
            code,
            baseProductId
        });
    }

    isUsedInProvisioning(baseProductId: number): Observable<boolean> {
        return this._httpClient.get(`/api/addon-product/can-update/${baseProductId}`).pipe(
            map((res: AddOnProductInProvisioning) => {
                return res.usedForDataCollectors || res.usedForDataOwners;
            })
        );
    }
}
