// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/pivot-item-list/pivot-item-list.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":["span {\n    display: inline-block;\n    vertical-align: top;\n    width: 100%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
