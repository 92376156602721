import { Injectable, inject } from "@angular/core";
import { ConfigService } from "./config.service";
import {
    NavigationConfiguration,
    NavigationConfigurationItem
} from "../types/navigation-configuration";
import { forkJoin, Observable, of } from "rxjs";
import { tap, catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { INavNode } from "@logex/framework/lg-application";

const NAVIGATION_PATH = "assets/config/navigation.json";

@Injectable()
export class NavigationConfigService {
    private _config = inject(ConfigService);
    private _httpClient = inject(HttpClient);

    configuration: NavigationConfiguration;
    configurationPromise: Promise<NavigationConfiguration>;
    private _resolve: (value: NavigationConfiguration) => void;

    constructor() {
        this.configurationPromise = new Promise<NavigationConfiguration>(resolve => {
            this._resolve = resolve;
        });
    }

    loadNavigation(): Observable<NavigationConfiguration> {
        const navigationPaths = this._config.configuration.navigationPaths;

        return forkJoin({
            userManagement: this._loadConfig(navigationPaths.userManagement),
            registryManagement: this._loadConfig(navigationPaths.registryManagement),
            products: this._loadConfig(navigationPaths.products),
            organizations: this._loadConfig(navigationPaths.organizations),
            clientsPortfolios: this._loadConfig(navigationPaths.clientsPortfolios)
        }).pipe(
            tap((navigationConfiguration: NavigationConfiguration) =>
                this._setConfigation(navigationConfiguration)
            )
        );
    }

    private _setConfigation(val: NavigationConfiguration): void {
        this.configuration = val;
        this._resolve(val);
        this._resolve = null;
    }

    private _loadConfig(appPrefix: string): Observable<NavigationConfigurationItem> {
        if (!appPrefix) {
            return of(new NavigationConfigurationItem());
        }

        return this._httpClient.get<NavigationConfigurationItem>(appPrefix + NAVIGATION_PATH).pipe(
            catchError(() => of(new NavigationConfigurationItem())),
            map((item: NavigationConfigurationItem) => this._setAbsolutePaths(item, appPrefix)),
            map((item: NavigationConfigurationItem) => this._disablePermissionsRestriction(item))
        );
    }

    // set absolute paths for other apps
    private _setAbsolutePaths(
        item: NavigationConfigurationItem,
        appPrefix: string
    ): NavigationConfigurationItem {
        if (appPrefix !== "/") {
            this._mapPaths(item.root, appPrefix);
            this._mapPaths(item.settings, appPrefix);
        }
        return item;
    }

    private _mapPaths(item: INavNode, pathPerfix: string): void {
        if (item && item.children && item.children.length) {
            item.children.forEach(x => {
                const prefix = item.path ? `${pathPerfix}${item.path}/` : pathPerfix;
                x.path = prefix + x.path;
                this._mapPaths(x, prefix);
            });
        }
    }

    // disable permission restriction for menu
    // everyone can see all menu items for now
    // access restriction are managed in each app separately on router level or on pages
    // TODO drop this when permission management is unified in all administration apps
    private _disablePermissionsRestriction(
        item: NavigationConfigurationItem
    ): NavigationConfigurationItem {
        this._mapPermission(item.root);
        this._mapPermission(item.settings);
        return item;
    }

    private _mapPermission(item: INavNode): void {
        if (!item) return;
        if (item.permissions) {
            item.permissions = null;
        }
        if (item.children && item.children.length) {
            item.children.forEach(x => {
                this._mapPermission(x);
            });
        }
    }
}
