export interface ConfiguredService {
    id: number;
    name: string;
    description?: string;
    serviceId: number;
    applicationsIds: number[];
}

export interface ConfiguredServiceCreateData {
    name: string;
    description?: string;
    serviceId: number;
    applicationsIds: number[];
}

export interface ConfiguredServiceUsed {
    usedByConfiguredDataset: boolean;
    usedByConfiguredDatasetsIds: number[];
    usedByConfiguredDatasetsNames?: string[];
    usedByBaseProduct: boolean;
    usedByBaseProductsIds: number[];
    usedByBaseProductsNames?: string[];
    usedByAddonProduct: boolean;
    usedByAddonProductsIds: number[];
    usedByAddonProductsNames?: string[];
}

export interface ConfiguredServiceInProvisioning {
    usedInDataCollectors: boolean;
    dataCollectorsIds: number[];
    usedInDataOwners: boolean;
    dataOwnersIds: number[];
}
