import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { LgMultiFilterComponent } from "@logex/framework/ui-core";

@Component({
    selector: "multi-filter-button",
    template: `<a
        class="button"
        [ngClass]="{
            hidden: _active,
            'button--condensed': condensed,
            'button-like button-like--disabled': disabled
        }"
        [class]="filterButtonClass"
        ><lg-icon [icon]="icon" [inline]="true"></lg-icon>{{ text }}</a
    >`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .button.hidden {
                visibility: hidden;
            }
        `
    ]
})
export class MultiFilterButtonComponent extends LgMultiFilterComponent {
    @Input() text: string;
    @Input() icon: string;
    @Input() filterButtonClass: string;
}
