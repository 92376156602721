import { HttpErrorResponse } from "@angular/common/http";
import { Component, Injectable, OnDestroy, ViewContainerRef, inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDropdownDefinition,
    LgConfirmationDialog,
    LgDialogRef
} from "@logex/framework/ui-core";
import { LgMultiFilterSourceValue } from "@logex/framework/ui-core/controls/lg-multi-filter/lg-multi-filter-popup.component";
import {
    AppDefinitions,
    ConfiguredService,
    ConfiguredServiceCreateData,
    ConfiguredServiceGateway,
    ConfiguredServiceUsed,
    DefinitionKey,
    Service,
    ServiceGateway,
    uniqueConfiguredServiceNameValidator
} from "app/shared";
import { idsToMultiFilter, multiFilterToIds } from "app/shared/helpers";
import ldIsEqual from "lodash-es/isEqual";
import ldKeyBy from "lodash-es/keyBy";
import ldSortBy from "lodash-es/sortBy";
import { forkJoin, of, Subject } from "rxjs";
import { distinctUntilChanged, finalize, takeUntil } from "rxjs/operators";
import { ApplicationInfoComponentDialog } from "./application-info/application-info.component";

@Component({
    selector: "app-configured-service-add-edit",
    templateUrl: "./configured-service-add-edit.component.html",
    styleUrls: ["./configured-service-add-edit.component.scss"],
    providers: [useTranslationNamespace("APP._Dialogs.ConfiguredServiceAddEditDialog")]
})
export class ConfiguredServiceAddEditComponent implements OnDestroy {
    private _applicationInfoComponentDialog = inject(ApplicationInfoComponentDialog);
    private _confirmDialog = inject(LgConfirmationDialog);
    private _definitions = inject(AppDefinitions);
    private _dialogRef = inject(LgDialogRef<ConfiguredServiceAddEditComponent>);
    private _fb = inject(UntypedFormBuilder);
    private _gateway = inject(ConfiguredServiceGateway);
    private _lgTranslate = inject(LgTranslateService);
    private _serviceGateway = inject(ServiceGateway);

    _title: string;
    _dialogClass = "lg-dialog lg-dialog--4col";

    _loading = true;
    _saving = false;
    _isUpdated = false;

    _form: UntypedFormGroup;

    _isNew: boolean;
    _configuredService: ConfiguredService;
    _showIsUsed: boolean;
    _isUsed: ConfiguredServiceUsed;
    _isUsedInProvisioning: boolean;
    _serviceId: number;
    _disableServiceId: boolean;
    _services: Record<string, Service>;
    _serviceDropdownDefinition: IDropdownDefinition<number>;
    _applicationDropdownDefinition: () => LgMultiFilterSourceValue;

    private _initFormValue: ConfiguredServiceCreateData;
    private _destroyed$ = new Subject<void>();

    constructor() {
        const _viewContainerRef = inject(ViewContainerRef);

        this._confirmDialog = this._confirmDialog.bindViewContainerRef(_viewContainerRef);
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    show(
        configuredService?: ConfiguredService,
        serviceId?: number,
        disableServiceId?: boolean
    ): Promise<ConfiguredService> {
        this._isNew = !configuredService;
        this._title = this._isNew
            ? this._lgTranslate.translate(".AddTitle")
            : this._lgTranslate.translate(".EditTitle");
        this._configuredService = configuredService;
        this._serviceId = configuredService?.serviceId || serviceId || null;
        this._disableServiceId = !!this._serviceId;

        this._load();

        return this._createPromise();
    }

    _confirm(): void {
        if (this._form.invalid) return;
        this._saving = true;

        let request = this._gateway.create(this._updatedService);
        let errorTitle = ".Errors.CreateErrorTitle";
        let errorText = ".Errors.CreateErrorText";

        if (!this._isNew) {
            request = this._gateway.update(this._updatedService);
            errorTitle = ".Errors.UpdateErrorTitle";
            errorText = ".Errors.UpdateErrorText";
        }

        request.pipe(finalize(() => (this._saving = false))).subscribe(
            (configuredService: ConfiguredService) => {
                this._definitions.clearCache("configuredService");
                this._resolve(configuredService);
                this._dialogRef.close();
            },
            (errorResponse: HttpErrorResponse) => {
                this._confirmDialog.warningLc(
                    errorTitle,
                    errorResponse?.error?.ErrorData || errorResponse?.error?.title || errorText
                );
            }
        );
    }

    _close(): void {
        this._dialogRef.close();
    }

    _cancel(): void {
        this._tryClose();
    }

    _tryClose(): boolean {
        if (this._saving) return;

        if (this._loading || ldIsEqual(this._initFormValue, this._updatedService)) {
            this._close();
            return;
        }

        this._confirmDialog
            .confirmLC(".ConfirmClose", ".ConfirmDiscardingUnsavedData")
            .then(response => {
                if (response === 1) {
                    this._close();
                }
            });
    }

    _showApplicationInfo(): void {
        if (this._form.get("applicationsIds").disabled) {
            this._applicationInfoComponentDialog.show(this._configuredService.applicationsIds);
        }
    }

    private _getRequiredDefinitions(): DefinitionKey[] {
        return ["service", "application", "configuredDataset", "product", "productAddOn"];
    }

    private _load(): void {
        forkJoin([
            this._serviceGateway.getAll(),
            this._definitions.load(...this._getRequiredDefinitions()),
            this._isNew ? of(null) : this._gateway.isUsed(this._configuredService.id),
            this._isNew ? of(null) : this._gateway.isUsedInProvisioning(this._configuredService.id)
        ])
            .pipe(
                takeUntil(this._destroyed$),
                finalize(() => (this._loading = false))
            )
            .subscribe(([services, definitions, isUsed, isUsedInProvisioning]) => {
                this._services = ldKeyBy(services, "id");
                this._initDropdowns();
                this._initIsUsed(isUsed);
                this._isUsedInProvisioning = isUsedInProvisioning;
                this._initForm();
                this._dialogRef.center();
            });
    }

    private _initForm(): void {
        this._form = this._fb.group({
            serviceId: [
                { value: this._serviceId, disabled: this._disableServiceId },
                [Validators.required]
            ],
            name: [
                { value: this._configuredService?.name, disabled: this._serviceId == null },
                [Validators.required],
                [
                    uniqueConfiguredServiceNameValidator(
                        this._gateway,
                        this._configuredService?.name || ""
                    )
                ]
            ],
            description: [
                { value: this._configuredService?.description, disabled: this._serviceId == null }
            ],
            applicationsIds: [
                {
                    value: idsToMultiFilter(
                        this._configuredService?.applicationsIds || [],
                        this._definitions.getSection("application")
                    ),
                    disabled: this._serviceId == null || this._isUsedInProvisioning
                }
            ]
        });

        this._form
            .get("serviceId")
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
                this._serviceId = value;
                if (value == null) {
                    this._form.get("name").reset("");
                    this._form.get("name").disable();
                    this._form.get("description").reset("");
                    this._form.get("description").disable();
                    this._form.get("applicationsIds").reset({});
                    this._form.get("applicationsIds").disable();
                } else {
                    this._form.get("name").enable();
                    this._form.get("description").enable();
                    this._form.get("applicationsIds").enable();
                    this._form.get("applicationsIds").reset({});
                }
            });

        this._initFormValue = { ...this._updatedService };

        if (!this._isNew) {
            this._form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
                this._isUpdated = !ldIsEqual(this._initFormValue, this._updatedService);
            });
        }
    }

    private _initDropdowns(): void {
        this._serviceDropdownDefinition = {
            groups: [
                {
                    entries: this._definitions.getSortedSectionData("service")
                }
            ]
        };

        this._applicationDropdownDefinition = () => {
            return ldSortBy(
                this._services[this._serviceId].linkedApplicationsIds.map(x =>
                    this._definitions.getEntry("application", x)
                ),
                item => item.name.toLowerCase()
            );
        };
    }

    private _initIsUsed(isUsed: ConfiguredServiceUsed): void {
        if (this._isNew) return;

        this._isUsed = isUsed;
        this._showIsUsed =
            isUsed.usedByAddonProduct || isUsed.usedByBaseProduct || isUsed.usedByConfiguredDataset;

        this._isUsed.usedByConfiguredDatasetsNames = this._isUsed.usedByConfiguredDatasetsIds.map(
            x => this._definitions.getDisplayName("configuredDataset", x)
        );

        this._isUsed.usedByBaseProductsNames = this._isUsed.usedByBaseProductsIds.map(x =>
            this._definitions.getDisplayName("product", x)
        );

        this._isUsed.usedByAddonProductsNames = this._isUsed.usedByAddonProductsIds.map(x =>
            this._definitions.getDisplayName("productAddOn", x)
        );
    }

    private get _updatedService(): ConfiguredService {
        const formValue = this._form.getRawValue();
        const updatedApplication = {
            id: this._configuredService?.id,
            serviceId: formValue.serviceId,
            name: formValue.name,
            description: formValue.description || undefined,
            applicationsIds: multiFilterToIds(formValue.applicationsIds)
        } as ConfiguredService;

        return updatedApplication;
    }

    private _resolve: (result: ConfiguredService) => void;
    private _reject: (reason?: any) => void;

    private _createPromise(): Promise<ConfiguredService> {
        return new Promise<ConfiguredService>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
}

// ----------------------------------------------------------------------------------
//
@Injectable()
export class ConfiguredServiceAddEditDialog extends getDialogFactoryBase(
    ConfiguredServiceAddEditComponent,
    "show"
) {}
