import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    forwardRef,
    Input
} from "@angular/core";

import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { LgDropdownComponent, LgDropdownPopupComponent } from "@logex/framework/ui-core";

@Component({
    selector: "dropdown-panel-icon-button",
    templateUrl: "./dropdown-panel-icon-button.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownPanelIconButtonComponent),
            multi: true
        }
    ]
})
export class DropdownPanelIconButtonComponent<
    T extends number | string
> extends LgDropdownComponent<T> {
    @Input() icon: string;
    @Input() text: string;

    _selectedIcon: string | null = null;

    constructor() {
        super();

        this.hideSearch = true;
        this.matchWidth = false;
        this._attachOnLeft = false;
        this.popupClassName =
            "lg-dropdown-popup lg-dropdown-popup--icon-menu lg-dropdown-popup--last-highlighted";
        this._selectedIcon = this.icon;
        this.focusEnabled = false;
    }

    protected _getPopupClass(): typeof LgDropdownPopupComponent {
        return LgDropdownPopupComponent;
    }
}
