export interface ProductDetail {
    id: number;
    brandId?: number;
    businessUnitId?: number;
    productRequesterId?: number;
    productDatasets: ProductDataset[];
    productServices: ProductService[];
}

export interface ProductDataset {
    $expanded: boolean;
    id: number;
    datasetServices: ProductService[];
}

export interface ProductService {
    $expanded: boolean;
    id: number;
    serviceApplicationsIds: number[];
}
