/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, inject } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import * as Filters from "@logex/framework/lg-filterset";
import {
    FilterFactoryCreatorBase,
    IFilterFactoryEntry,
    MapFilterDefinition,
    MapFilterStore,
    SupportedFilters
} from "./bases/filter-factory-base";
import { AppDefinitions, DefinitionKey } from "./services";
import ldSortBy from "lodash-es/sortBy";

// ---------------------------------------------------------------------------------------------
//  Implementation of the FilterFactory service
// ---------------------------------------------------------------------------------------------

@Injectable({
    providedIn: "root"
})
export class FilterFactory {
    private _appDefinitions = inject(AppDefinitions);
    private _filterSetService = inject(Filters.LgFilterSetService);
    private _lgTranslateService = inject(LgTranslateService);

    define(): FilterFactoryCreator {
        return new FilterFactoryCreator(
            this._filterSetService,
            this._lgTranslateService,
            this._appDefinitions
        );
    }
}

// ---------------------------------------------------------------------------------------------
type Combo2Parameters<T> = Pick<
    Filters.IComboFilter2Definition<T>,
    | "source"
    | "onBecameActive"
    | "onBecameActiveLimit"
    | "onChanged"
    | "visible"
    | "name"
    | "nameLC"
>;

type SelectableCombo2Parameters<T> = Pick<
    Filters.ISelectableComboFilter2Definition<T>,
    | "source"
    | "onBecameActive"
    | "onBecameActiveLimit"
    | "onChanged"
    | "visible"
    | "nameLC"
    | "onSelectionChanged"
    | "onSelectionRemoved"
>;

type CheckboxParameters = Pick<
    Filters.ICheckboxFilterDefinition,
    | "uncheckedFilters"
    | "default"
    | "previewTextLC"
    | "previewPopup"
    | "nameLC"
    | "onChanged"
    | "visible"
>;

type InputRangeParameters = Pick<
    Filters.IInputRangeFilterDefinition,
    "min" | "max" | "format" | "decimals" | "default" | "onChanged" | "visible" | "nameLC"
>;

// ---------------------------------------------------------------------------------------------
//  Implementation of the creator.
// ---------------------------------------------------------------------------------------------
export class FilterFactoryCreator<
    Definitions extends Record<string, Filters.INormalizedFilterDefinition> = {},
    Filters extends Filters.IFilterList = {}
> extends FilterFactoryCreatorBase {
    constructor(
        _filterSetService: Filters.LgFilterSetService,
        _lgTranslateService: LgTranslateService,
        private _appDefinitions: AppDefinitions
    ) {
        super(_filterSetService, _lgTranslateService);
    }

    // ---------------------------------------------------------------------------------------------
    addFilter<T extends SupportedFilters, N extends string>(
        id: N,
        params: T
    ): FilterFactoryCreator<
        Definitions & {
            [P in N]: MapFilterDefinition<T>;
        },
        Filters & { [P in N]: MapFilterStore<T> }
    > {
        return this._addFilter(id, params);
    }

    // ---------------------------------------------------------------------------------------------
    create(context: any): Filters.LgFilterSet<Definitions, Filters> {
        return this._create<Definitions, Filters>(context);
    }

    // ---------------------------------------------------------------------------------------------
    addCheckboxFilter<T extends string>(
        id: T,
        params: CheckboxParameters
    ): FilterFactoryCreator<any, any> {
        return this._createCheckbox(id, params, null);
    }

    // ---------------------------------------------------------------------------------------------
    //  Private helpers
    // ---------------------------------------------------------------------------------------------
    private _createCombo2Number<T extends string>(
        id: T,
        params: Combo2Parameters<number>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>>
    ): FilterFactoryCreator<any, any> {
        return this.addFilter(id, {
            filterType: "combo2",
            main: true,
            idType: "number",
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.IComboFilter2Definition<number>>);
    }

    // ---------------------------------------------------------------------------------------------
    private _createCombo2String<T extends string>(
        id: T,
        params: Combo2Parameters<string>,
        defaults: Partial<IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>>
    ): FilterFactoryCreator<any, any> {
        return this.addFilter(id, {
            filterType: "combo2",
            main: true,
            idType: "string",
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.IComboFilter2Definition<string>>);
    }

    private _createInputRange<T extends string>(
        id: T,
        params: InputRangeParameters,
        defaults: Partial<InputRangeParameters>
    ): FilterFactoryCreator<any, any> {
        return this.addFilter(id, {
            filterType: "inputRange",
            main: true,
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.IInputRangeFilterDefinition>);
    }

    // ---------------------------------------------------------------------------------------------
    private _createCheckbox<T extends string>(
        id: T,
        params: CheckboxParameters,
        defaults: Partial<IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>>
    ): FilterFactoryCreator<any, any> {
        return this.addFilter(id, {
            filterType: "checkbox",
            main: true,
            visible: () => true,
            ...defaults,
            ...params
        } as IFilterFactoryEntry<Filters.ICheckboxFilterDefinition>);
    }

    // ---------------------------------------------------------------------------------------------
    private _createMapper<T extends number | string>(id: DefinitionKey, sortById: boolean) {
        return (codes: T[]) => {
            const mappedCodes = codes.map(code => {
                const definitionEntry = this._appDefinitions.getEntry(id, code);
                const displayName = definitionEntry
                    ? definitionEntry.name
                    : this._lgTranslateService.translate("APP._Filters.Unknown");

                return {
                    id: code,
                    order: code,
                    name: displayName
                };
            });
            return ldSortBy(mappedCodes, f => f.order);
        };
    }
}
