import { Component, Injectable, OnDestroy, ViewContainerRef, inject } from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { getDialogFactoryBase, LgConfirmationDialog, LgDialogRef } from "@logex/framework/ui-core";
import {
    AppDefinitions,
    BaseProductGateway,
    DefinitionKey,
    ProductAddOnGateway,
    ProductDetail
} from "app/shared";
import { forkJoin, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-product-detail",
    templateUrl: "./product-detail.component.html",
    styleUrls: ["./product-detail.component.scss"],
    providers: [useTranslationNamespace("APP._Dialogs.ProductDetail")]
})
export class ProductDetailComponent implements OnDestroy {
    _definitions = inject(AppDefinitions);
    private _addOnGateway = inject(ProductAddOnGateway);
    private _confirmDialog = inject(LgConfirmationDialog);
    private _dialogRef = inject(LgDialogRef<ProductDetailComponent>);
    private _lgTranslate = inject(LgTranslateService);
    private _productsGateway = inject(BaseProductGateway);

    _title: string;
    _dialogClass = "lg-dialog lg-dialog--6col";

    _isLoading = false;
    _productDetail: ProductDetail;

    _isAddon: boolean;
    private _productId: number;

    private _destroyed$ = new Subject<void>();

    constructor() {
        const _viewContainerRef = inject(ViewContainerRef);

        this._confirmDialog = this._confirmDialog.bindViewContainerRef(_viewContainerRef);
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    show(productId: number, productName: string, isAddon: boolean): Promise<void> {
        this._productId = productId;
        this._isAddon = isAddon;
        this._title = this._lgTranslate.translate(".Title", { name: productName });

        this._load();

        return this._createPromise();
    }

    private _getRequiredDefinitions(): DefinitionKey[] {
        const sharedDefinitions: DefinitionKey[] = ["dataset", "service", "application", "brand"];

        return this._isAddon
            ? [...sharedDefinitions, "productAddOn"]
            : [...sharedDefinitions, "product", "baseProductRequester", "businessUnit"];
    }

    private _load(): void {
        this._isLoading = true;
        forkJoin([
            this._isAddon
                ? this._addOnGateway.getDetail(this._productId)
                : this._productsGateway.getDetail(this._productId),
            this._definitions.load(...this._getRequiredDefinitions())
        ])
            .pipe(
                takeUntil(this._destroyed$),
                finalize(() => (this._isLoading = false))
            )
            .subscribe(([productDetail]) => {
                this._productDetail = productDetail;
                requestAnimationFrame(() => this._dialogRef.center());
            });
    }

    private _resolve: (result: void) => void;
    private _reject: (reason?: any) => void;

    private _createPromise(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
}

// ----------------------------------------------------------------------------------
//
@Injectable()
export class ProductDetailDialog extends getDialogFactoryBase(ProductDetailComponent, "show") {}
