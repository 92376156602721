export interface DatasetDictionary {
    id: number;
    name: string;
    code: string;
    surveyCode: string;
    description?: string;
    datasetId: number;
    statusId: number;
    version: number;
    documentation: string;
    latestRelease: string;
}
