import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    ConfiguredService,
    ConfiguredServiceCreateData,
    ConfiguredServiceInProvisioning,
    ConfiguredServiceUsed,
    DuplicityCheckResponse,
    DuplicityNameCheckResponse
} from "../types";
import { GatewayBase } from "../bases";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ConfiguredServiceGateway extends GatewayBase {
    private _httpClient = inject(HttpClient);

    getAll(): Observable<ConfiguredService[]> {
        return this._httpClient.post<ConfiguredService[]>(`/api/configured-service`, {});
    }

    create(service: ConfiguredServiceCreateData): Observable<ConfiguredService> {
        return this._httpClient.post<ConfiguredService>(`/api/configured-service/create`, service);
    }

    update(service: ConfiguredService): Observable<ConfiguredService> {
        return this._httpClient.patch<ConfiguredService>(
            `/api/configured-service/${service.id}`,
            service
        );
    }

    checkDuplicity(name: string): Observable<DuplicityNameCheckResponse> {
        return this._httpClient.post<DuplicityCheckResponse>(`/api/configured-service/duplicity`, {
            name
        });
    }

    canDelete(configuredServiceId: number): Observable<boolean> {
        return this._httpClient
            .get<ConfiguredServiceUsed>(`/api/configured-service/can-delete/${configuredServiceId}`)
            .pipe(
                map((res: ConfiguredServiceUsed) => {
                    return !(
                        res.usedByAddonProduct ||
                        res.usedByBaseProduct ||
                        res.usedByConfiguredDataset
                    );
                })
            );
    }

    isUsedInProvisioning(configuredServiceId: number): Observable<boolean> {
        return this._httpClient
            .get(`/api/configured-service/can-update/${configuredServiceId}`)
            .pipe(
                map((res: ConfiguredServiceInProvisioning) => {
                    return res.usedInDataCollectors || res.usedInDataOwners;
                })
            );
    }

    isUsed(configuredServiceId: number): Observable<ConfiguredServiceUsed> {
        return this._httpClient.get<ConfiguredServiceUsed>(
            `/api/configured-service/can-delete/${configuredServiceId}`
        );
    }

    delete(configuredServiceId: number): Observable<ConfiguredServiceUsed> {
        return this._httpClient.delete<ConfiguredServiceUsed>(
            `/api/configured-service/delete/${configuredServiceId}`
        );
    }
}
