import { INavNode } from "@logex/framework/lg-application";

export class NavigationPaths {
    userManagement: string;
    registryManagement: string;
    products: string;
    organizations: string;
    clientsPortfolios: string;
}

export class NavigationConfiguration {
    userManagement: NavigationConfigurationItem;
    registryManagement: NavigationConfigurationItem;
    products: NavigationConfigurationItem;
    organizations: NavigationConfigurationItem;
    clientsPortfolios: NavigationConfigurationItem;
}

export class NavigationConfigurationItem {
    root: INavNode;
    settings: INavNode;

    constructor() {
        this.root = null;
        this.settings = null;
    }
}
