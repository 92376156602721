import { Component, Input, inject } from "@angular/core";
import { AppDefinitions } from "app/shared";
import { ProductService } from "app/shared/types/product-detail";

@Component({
    selector: "app-product-services",
    templateUrl: "./product-services.component.html",
    styleUrls: ["./product-services.component.scss"]
})
export class ProductServicesComponent {
    _definitions = inject(AppDefinitions);

    @Input() services: ProductService[];
}
